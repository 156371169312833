import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomValidarCompras
 * @author: Santiago Hernández N
 * @version: jdesk_jdesk_1.01.0002
 **/
class CustomValidarCompras extends FormJaivana.form {

  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.traerDatosLista                   = this.traerDatosLista.bind(this);
    this.sucessTraerDatosLista             = this.sucessTraerDatosLista.bind(this);
    this.calcularTotal                     = this.calcularTotal.bind(this);
    this.totalCompra                       = 0;         
    this.aceptarCompra                     = this.aceptarCompra.bind(this);       
    this.successAceptarCompra              = this.successAceptarCompra.bind(this);       
    this.successGrabarCartera              = this.successGrabarCartera.bind(this);       
    this.limpiar                           = this.limpiar.bind(this);       
  }

  initForm() {
    console.log("Formulario CustomValidarCompras,  @version: jdesk_1.01.0004, @author: Santiago Hernández N");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.traerDatosLista();
    this.getField('valor_retefuente').setOnChange(this.calcularTotal);
    this.getField('valor_reteiva').setOnChange(this.calcularTotal);
    this.getField('valor_reteica').setOnChange(this.calcularTotal);
    this.getField("aceptar").setClick(this.aceptarCompra);
    this.getField("cancelar").setClick(this.limpiar);
  }

  traerDatosLista(){
    let numero_factura = this.props.data.factura_numero
    let datos={ datos: {
      factura_numero: numero_factura
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-validarcompra', operacion: 'traercompra', operacion_tipo: 'consulta' };
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method:'GET',
      body: datos,
      success: this.sucessTraerDatosLista,
      error: this.error_,
      general: this.generalFormatPmv,
      showMessage: false
    });    
  }

  sucessTraerDatosLista(data){
    if(data.estado_p === 200){ 
      let datosFicha = [];
      this.lista = data.data[0];
      let data2 = {
        "numero_factura": this.lista.numero,
        "fecha": this.lista.fecha,
        "proveedor": this.lista.nproveedor,
        "valor_sin_impuestos": "".formatoPrecio(this.lista.valor_sin_impuestos),
        "valor_iva":  "".formatoPrecio(this.lista.valor_iva),
        "orden_compra": this.lista.pedido_compra,
      };

      datosFicha.push(data2);
      this.getField('li_datos_compras').setItemsFichas(datosFicha);
      this.getField('total').setValue(Number(this.lista.valor));
      this.totalCompra = Number(this.lista.valor);
      this.calcularTotal();

    }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre la compra.', 'error');
    }else{
      let respuesta=Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
  }

  calcularTotal(){   
    let total = this.totalCompra - Number(this.getField('valor_retefuente').getValue()) - Number(this.getField('valor_reteiva').getValue()) - Number(this.getField('valor_reteica').getValue(''));    
    this.getField('total').setValue(total);
  }

  aceptarCompra(){
    if(this.getField('retefuente_tarifa').valid() && this.getField('valor_retefuente').valid() &&    
      this.getField('reteiva_tarifa').valid() && this.getField('valor_reteiva').valid() &&
      this.getField('reteica_tarifa').valid() && this.getField('valor_reteica').valid() &&
      this.getField('total').valid() ){
        let datos={ datos: { 
    
          numero_factura:this.lista.numero,
          retefuente_tarifa:this.getField('retefuente_tarifa').getValue(),  
          valor_retefuente:this.getField('valor_retefuente').getValue(),     
          reteiva_tarifa:this.getField('reteiva_tarifa').getValue(),  
          valor_reteiva:this.getField('valor_reteiva').getValue(),     
          reteica_tarifa:this.getField('reteica_tarifa').getValue(),  
          valor_reteica:this.getField('valor_reteica').getValue(),      
          //items:opciones
          
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-validarcompra', operacion: 'aceptarcompra', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successAceptarCompra,
            error: this.error_,
            general: this.generalFormatPmv
        });
      }
  }
  
  successAceptarCompra(data){
    if(data.estado_p === 200){ 
      let datos={ datos: { 
        sucursal_ingreso : this.sucursal_ingreso,
        nit: this.lista.nit,
        sede: this.lista.sede,
        xsucursal: this.lista.codigo_sucursal,
        factura_numero:this.props.data.factura_numero,
        fecha_emision_actual:this.lista.fecha_emision,
        fecha_vencimiento:this.lista.vence,
        valor_neto:this.getField('total').getValue()

      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-validarcompra', operacion: 'guardarcartera', operacion_tipo: 'crear' };
      this.service.send(
      {
          endpoint: this.constant.formConfiguration(),
          method:'POST',
          body: datos,
          success: this.successGrabarCartera,
          error: this.error_,
          general: this.generalFormatPmv
      });
    }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'No grabo en facturac impuestos.', 'error');
    }else{
      let respuesta=Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 

  }

  successGrabarCartera(data){
    if(data.estado_p === 200){ 
      let data_envio={
          integrar_contable:true,
          factura_numero:this.props.data.factura_numero,
          //orden_compra:no_orden
      }
      let datos={ datos: {
        data:data_envio,
        proceso_id:33,
        procesos_actividades_id:32,
        procesos_actividades_configuracion_id:15,
        referencia:this.props.data.factura_numero,
        tabla:".",
        registro_id:1,
        nombre_proceso:"Nueva Compra",
        descripcion_proceso:this.totalCompra.toString()
      }};
      this.generalFormatPmv = { tipo_servicio: 'proc-procesos', operacion: '6', operacion_tipo: 'modificar' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'PUT',
              body: datos,
              success: this.successCloseEstage,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'No grabo en facturac impuestos.', 'error');
    }else{
      let respuesta=Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
  }

  limpiar(){
    this.getField('retefuente_tarifa').setValue(0);  
    this.getField('valor_retefuente').setValue(0);     
    this.getField('reteiva_tarifa').setValue(0);  
    this.getField('valor_reteiva').setValue(0);     
    this.getField('reteica_tarifa').setValue(0);  
    this.getField('valor_reteica').setValue(0);

    this.getField('retefuente_tarifa').setError(false,"");  
    this.getField('valor_retefuente').setError(false,"");     
    this.getField('reteiva_tarifa').setError(false,"");  
    this.getField('valor_reteiva').setError(false,"");     
    this.getField('reteica_tarifa').setError(false,"");  
    this.getField('valor_reteica').setError(false,"");  
  }

}
FormJaivana.addController("comp-validarcompra", CustomValidarCompras);
export default CustomValidarCompras;