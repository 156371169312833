import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomListaClientes
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0004
 **/
class CustomListaClientes extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                                           = this.initForm.bind(this);
        this.nuevoPedidosPendientes                             = this.nuevoPedidosPendientes.bind(this);
        this.successNuevoPendientes                             = this.successNuevoPendientes.bind(this);
        this.susccessRespuestaSegundoServ                       = this.susccessRespuestaSegundoServ.bind(this);
        this.mostrarClientes                                    = this.mostrarClientes.bind(this);
        this.setButtonDelete                                    = this.setButtonDelete.bind(this);
        this.setButtonCerrarPedido                              = this.setButtonCerrarPedido.bind(this);
        this.selectionNumeroPedido                              = this.selectionNumeroPedido.bind(this);
        this.selectionRegistro                                  = this.selectionRegistro.bind(this);
        this.seleccionCliente                                   = this.seleccionCliente.bind(this);
        this.seleccionDocumento                                 = this.seleccionDocumento.bind(this);
        this.seleccionSede                                      = this.seleccionSede.bind(this);
        this.seleccionFecha                                     = this.seleccionFecha.bind(this);
        this.seleccionTotal                                     = this.seleccionTotal.bind(this);
        this.seleccionTipoPedido                                = this.seleccionTipoPedido.bind(this);
        this.setButtonDevolver                                  = this.setButtonDevolver.bind(this);
        this.mostrarProductos                                   = this.mostrarProductos.bind(this);
        this.successDatosClientes2                              = this.successDatosClientes2.bind(this);
        this.successProductosClientes                           = this.successProductosClientes.bind(this);
        this.recuperarCodigosArray                              = this.recuperarCodigosArray.bind(this);
        this.initModalProductos                                 = this.initModalProductos.bind(this);
        this.setButtonImprimirPedido                            = this.setButtonImprimirPedido.bind(this);
        this.cerrarPedido                                       = this.cerrarPedido.bind(this);
        this.successCerrarPedido                                = this.successCerrarPedido.bind(this);
        this.confirmContarPedido                                = this.confirmContarPedido.bind(this);
        this.successContarPedido                                = this.successContarPedido.bind(this);
        this.ImprimirPedido                                     = this.ImprimirPedido.bind(this);
        this.TraerDatosClienteFinal                             = this.TraerDatosClienteFinal.bind(this);
        this.successTraerDatosClienteFinal                      = this.successTraerDatosClienteFinal.bind(this);
        this.confirmDevolverTodo                                = this.confirmDevolverTodo.bind(this);
        this.consultarItemsDevolver                             = this.consultarItemsDevolver.bind(this);
        this.successItemsDevolver                               = this.successItemsDevolver.bind(this);
        this.successEliminarItems                               = this.successEliminarItems.bind(this);
        this.eliminarItemsPedido                                = this.eliminarItemsPedido.bind(this);
        this.successTraerConsecutivo                            = this.successTraerConsecutivo.bind(this);
        this.buscarClientesPorNit                               = this.buscarClientesPorNit.bind(this);
        this.traerDatosCliente                                  = this.traerDatosCliente.bind(this);
        this.habilitadoEnviarMSM                                = this.habilitadoEnviarMSM.bind(this);
        this.successMsm                                         = this.successMsm.bind(this);
        this.ActualizarDatos                                    = this.ActualizarDatos.bind(this);
        this.successActualizar                                  = this.successActualizar.bind(this);
        this.traerDatos                                         = this.traerDatos.bind(this);
        this.successProductosPdf                                = this.successProductosPdf.bind(this);
        this.enviarCorreo                                       = this.enviarCorreo.bind(this);
        this.successEnviarCorreo                                = this.successEnviarCorreo.bind(this);
        this.enviarsms                                          = this.enviarsms.bind(this);
        this.successEnviarSms                                   = this.successEnviarSms.bind(this);
        this.generarPdfTabla                                    = this.generarPdfTabla.bind(this);
        this.guardarCliente                                     = this.guardarCliente.bind(this);
        this.traerDepartamentos                                 = this.traerDepartamentos.bind(this);
        this.successTraerDepartamentos                          = this.successTraerDepartamentos.bind(this);
        this.traerCiudades                                      = this.traerCiudades.bind(this);
        this.successTraerCiudades                               = this.successTraerCiudades.bind(this);
        this.agregarCliente                                     = this.agregarCliente.bind(this);
        this.limpiarMensajesErrorModalAgregarCliente            = this.limpiarMensajesErrorModalAgregarCliente.bind(this);
        this.buscarClientePorNit                                = this.buscarClientePorNit.bind(this);
        this.limpiarCampos                                      = this.limpiarCampos.bind(this);
        this.traerCodigoBodegaSaldo                             = this.traerCodigoBodegaSaldo.bind(this);
        this.succesTraerCodBodegaSaldo                          = this.succesTraerCodBodegaSaldo.bind(this);
        this.setButtonDeleteProducto                            = this.setButtonDeleteProducto.bind(this);
        this.eliminarProducto                                   = this.eliminarProducto.bind(this);
        this.confirmDeleteCustom                                = this.confirmDeleteCustom.bind(this);
        this.successDelete                                      = this.successDelete.bind(this);
        this.uploadTable                                        = this.uploadTable.bind(this);
        this.descuentoChange                                    = this.descuentoChange.bind(this);
        this.successGuardarDescuento                            = this.successGuardarDescuento.bind(this);
        this.successUploadTable                                 = this.successUploadTable.bind(this);
        this.buscarProductos                                    = this.buscarProductos.bind(this);
        this.initModalBodegas                                   = this.initModalBodegas.bind(this);
        this.codigoFacturacion                                  = this.codigoFacturacion.bind(this);
        this.guardarProducto                                    = this.guardarProducto.bind(this);
        this.successDatosClientes                               = this.successDatosClientes.bind(this);
        this.successModificarDescuento                          = this.successModificarDescuento.bind(this);
        this.mensajeErrorProducto                               = this.mensajeErrorProducto.bind(this);
        this.addProductoCliente                                 = this.addProductoCliente.bind(this)
        this.calcularPrecioProducto                             = this.calcularPrecioProducto.bind(this);
        this.totalProducto2                                     = this.totalProducto2.bind(this);
        this.totalProducto                                      = this.totalProducto.bind(this);
        this.formatearPrecios                                   = this.formatearPrecios.bind(this);
        this.modalConfirmDescuento                              = this.modalConfirmDescuento.bind(this);
        this.addProdutoACliente                                 = this.addProdutoACliente.bind(this);
        this.successValidarDescuento                            = this.successValidarDescuento.bind(this);
        this.validarDescuentoMax                                = this.validarDescuentoMax.bind(this);
        this.successValidarDescuentoMax                         = this.successValidarDescuentoMax.bind(this);
        this.habilitarBusquedaNombreMarca                       = this.habilitarBusquedaNombreMarca.bind(this);
        this.setPrecioIncluyeIva                                = this.setPrecioIncluyeIva.bind(this);
        this.confirmCloseAlertModalDescuento                    = this.confirmCloseAlertModalDescuento.bind(this);
        this.manejaRemision                                     = this.manejaRemision.bind(this);
        this.successManejaRemision                              = this.successManejaRemision.bind(this);
        this.VerMargen                                          = this.VerMargen.bind(this);
        this.successVerMargen                                   = this.successVerMargen.bind(this);
        this.successManejaConteo                                = this.successManejaConteo.bind(this);
        this.calcularTotalProductoAgregarConIvaN                = this.calcularTotalProductoAgregarConIvaN.bind(this);
        this.precioTotalIvaCantidadDecimalConIvaN               = this.precioTotalIvaCantidadDecimalConIvaN.bind(this);
        this.confirmAceptarCustom                               = this.confirmAceptarCustom.bind(this);
        this.successGuardarProducto                             = this.successGuardarProducto.bind(this);
        this.consultarExistenciasBodegas                        = this.consultarExistenciasBodegas.bind(this);
        this.mostrarProductosBodegas                            = this.mostrarProductosBodegas.bind(this);
        this.buscarProductosFiltrar                             = this.buscarProductosFiltrar.bind(this);
        this.selectionRegistro2                                 = this.selectionRegistro2.bind(this);
        this.currencyFormatterGeneral                           = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
        this.setButtonValorActual                               = this.setButtonValorActual.bind(this);
        this.traerProductosCoincidenciaNombreCoincidenciaMarca  = this.traerProductosCoincidenciaNombreCoincidenciaMarca.bind(this);
        this.traerPaises                                        = this.traerPaises.bind(this);
        this.successTraerPaises                                 = this.successTraerPaises.bind(this);
        this.tipoDocumento                                      = this.tipoDocumento.bind(this);
        this.concatenarNombre                                   = this.concatenarNombre.bind(this);
        this.validarCamposApellidos                             = this.validarCamposApellidos.bind(this);
        this.calcularDigitoNit                                  = this.calcularDigitoNit.bind(this);
        this.successGuardarCliente                              = this.successGuardarCliente.bind(this);
        this.validarItemsCotizacionIntegrar                     = this.validarItemsCotizacionIntegrar.bind(this);
        this.intgrarItemsCotizacion                             = this.intgrarItemsCotizacion.bind(this);
        this.successMostradorProductosCrear                     = this.successMostradorProductosCrear.bind(this);
        this.validarServicioMostradorProdCrear                  = this.validarServicioMostradorProdCrear.bind(this);
        this.traerInfoPedidos                                   = this.traerInfoPedidos.bind(this);
        this.traerInformacionPedidos                            = this.traerInformacionPedidos.bind(this);
        this.traertodopornumero                                 = this.traertodopornumero.bind(this);
        this.itemsPedidoPorNumero                               = this.itemsPedidoPorNumero.bind(this);
        this.successTraerIdClientePorNitSede                    = this.successTraerIdClientePorNitSede.bind(this);
        this.mostProdBodegaSegunSucursal                        = this.mostProdBodegaSegunSucursal.bind(this);
        this.mostProdSucursalBodegas                            = this.mostProdSucursalBodegas.bind(this);
        this.successMostProdSucursalBodegas                     = this.successMostProdSucursalBodegas.bind(this);
        this.clientesPendientes                                 = this.clientesPendientes.bind(this);
        this.successClientesPendientes                          = this.successClientesPendientes.bind(this);
        this.buscarMarca                                        = this.buscarMarca.bind(this);
        this.cambiarEstadoDescuento                             = this.cambiarEstadoDescuento.bind(this);
        this.succesCambioEstado                                 = this.succesCambioEstado.bind(this);
        this.calcularTotalProductoAgregarConIvaS                = this.calcularTotalProductoAgregarConIvaS.bind(this); 
        this.precioTotalIvaCantidadDecimalConIvaS               = this.precioTotalIvaCantidadDecimalConIvaS.bind(this);
        this.filtrarNombre                                      = this.filtrarNombre.bind(this);

        //Grid de las diferentes tablas 
        this.gridOptionsTablaTerceros                           = Object.assign({}, this.gridOptions);
        this.gridOptions                                        = Object.assign({}, this.gridOptions);
        this.gridOptionsProducto                                = Object.assign({}, this.gridOptions);
        this.gridOptionsProductoBodegas                         = Object.assign({}, this.gridOptions);
        this.gridOptionsTercerosSede                            = Object.assign({}, this.gridOptions);
        this.gridOptionsProductos                               = Object.assign({}, this.gridOptions);
        this.onSelectionChanged                                 = this.onSelectionChanged.bind(this);
        this.gridOptionsProductos['onSelectionChanged']         = this.onSelectionChanged;
        this.gridOptionsItemsCotizacion                         = Object.assign({}, this.gridOptions);

        //variables globales
        this.case  = null;
        this.dataClientePedido = [];
        this.seguir = false;
        this.arrayPendientes = [];
        this.arrayNuevo = [];
        this.contDevolver = 0;
        this.arrayIdsEliminar = [];
        this.precio_cliente = '';
        this.id_cliente = 0;
        this.cliente_id = 0;
        this.dataCliente = [];
        this.totalCantidadProductos = 0;
        this.nitCliente = '';
        this.sedeCliente = 0;
        this.preciosCliente = '';
        this.arrayTabla = ''
        this.precio_base = 0;
        this.filaSeleccionadaGrid = [];
        this.ids = '';
        this.clicked = false;
        this.itemsPedido = []; // Esta variable de tipo arreglo va a servir para almacenar los items del pedido cotización consultado.
        this.preciosMostradorProductosCrear = '';
        this.nitsxSedesIdMostradorProductosCrear = 0;
        this.contadorIntegrar = 0;
        this.arrayMsjConfimDialog = [];
        this.estadoBodegasExistencias = false; // Esta variable global de tipo booleana va a servir para saber si el servicio "mostradorproductos-crear" respondigo alguna vez con estado_p = 400 al momento de integrar los items de pedido cotización.
        this.arrayFinalIntegrarItemsCotizacion = []; // Esta variable de tipo arreglo va a servir para almacenar los items de concatenados de items_pedidos e items_saldos_bodegas_por_producto.
        this.copiaData = [];
        this.saldo_bodegas_productos = '';
        this.agregarDescuento = false;
        this.arrayPendientes = [];
        this.arrayNuevo = [];
        this.contDevolver = 0;
        this.arrayIdsEliminar = [];
        this.registroSeleccionado = '';
        this.codigo_usuario = '';
        this.maneja_remision = false;
        this.maneja_remision_producto = 'N';
        this.ver_margen = 'N';
        this.codigoConsecutivo = 0;
        this.maneja_conteo = false;
        this.habilitadoMsm = false;
        
    }

    initForm() {
        console.log("Formulario CustomListaClientes,  @version: jdesk_1.01.0004, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.getField("codigo_sucursal").setValue(this.codigo_sucursal);
        this.nuevoPedidosPendientes();
        this.VerMargen();

        this.getField('btnAgregarProducto').setDisabled(true);

        this.getField("btnNuevoPedido").setClick(() => {
            this.getField("sede_cliente_nuevo_pedido").setError(false,"");
            this.getField("nombre_cliente_nuevo_pedido").setError(false,"");
            this.getField("clienteNuevoPedido").setError(false,"");
            this.getField('tipo_pedido').setError(false,"");
            this.getField('tipo_despacho').setError(false,"");
            this.getField('tipo_pedido').setValue('Entrega Inmediata');
            this.getField('tipo_despacho').setValue('Envio Local');

            this.getField("modalNuevoPedido").handleClickOpen();
        });

        this.getField("tipo_pedido").setOnChange(() => {
            if (this.getField("tipo_pedido").getValue() === 'Entrega Inmediata'){
                this.getField('tipo_despacho').setDisabled(true);
                this.getField('tipo_despacho').setValue('Envio Local');
            }else{
                this.getField('tipo_despacho').setDisabled(false);
            }
        });

        this.getField("btnAceptarNuevoPedido").setClick(() => {
            this.tipoPedido = this.getField('tipo_pedido').getValue();
            this.tipoDespacho = this.getField('tipo_despacho').getValue();

            if(this.getField("clienteNuevoPedido").valid() && this.getField("sede_cliente_nuevo_pedido").valid()){
                // Consumir servicio nuevo
                this.getField("nombre_cliente_nuevo_pedido").setError(false,"");

                let datos={ datos: {
                    codigo_sucursal:this.codigo_sucursal
                }};
                this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'traerconsecutivo', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerConsecutivo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        });

        this.habilitadoEnviarMSM();

        this.getField("btnCancelarNuevoPedido").setClick(() => {
            this.getField("modalNuevoPedido").handleClose();
        });

        this.getField("enviar_datos").setClick(() => {
            if(this.getField("correo_usuario").valid() && this.getField("numero_telefono").valid()){
                this.getField("correo_usuario").setError(false,""); 
                this.getField("numero_telefono").setError(false,""); 
                this.ActualizarDatos();
            }
        });

        this.getField('pedidos_pendientes').setClick(() => {
            this.getField('pedidos_pendientes').setDisabled(true);
            this.getField('cliente').setValue('');
            this.getField('sede_cliente').setValue('');
            this.getField('nombre_cliente').setValue('');
            this.getField('razon_social').setValue('');
            this.getField('tercero_id_sede').setValue('');
            this.getField('cliente').setError(false, '');

            this.gridOptionsTablaTerceros['rowData'] = [];
            this.getField('tabla_terceros').initData(this.gridOptionsTablaTerceros);
            this.getField('tabla_terceros').toggle(false);

            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').initData(this.gridOptions);
            this.getField('tablaClientes').toggle(false);
            this.registroSeleccionado = '';
            this.arrayNuevo = [];
            this.nuevoPedidosPendientes();
        });

        this.getField('btnAgregarCliente').setClick(this.guardarCliente);

        this.getField('paises_id').setOnChange(this.traerDepartamentos);
        this.getField('departamento_geografico_id').setOnChange(this.traerCiudades);
        this.getField('btnNuevoFerri').setClick(this.agregarCliente);
        this.getField('razon_social').setOnChange(this.buscarClientePorNit);
        this.getField('cliente').setOnChange(this.limpiarCampos);
        this.getField('btnBuscarProductoBodegas').setClick(this.buscarProductos);
        this.getField('sucursal_ingreso').setValue(JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal);
        this.traerCodigoBodegaSaldo();
        this.getField('nombre_producto_bodegas').setOnChange(this.consultarExistenciasBodegas);
        this.getField("btnFiltrar").setClick(this.buscarProductosFiltrar);

        this.manejaRemision();
        this.getField('bt_buscar_por_nombre_marca').setSuccess(this.traerProductosCoincidenciaNombreCoincidenciaMarca);
        this.getField('modalAgregarProducto').setCloseButton(this.mensajeErrorProducto);

        this.getField('modalProducto').setCloseButton(() => {
            this.copiaData = [];
            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').toggle(false);
            this.nuevoPedidosPendientes();
            this.getField('listaProductos').setItemsFichas('');
        });

        this.getField('modalAgregarProducto').setCloseButton(() => {
            this.getField('listaAgregarProducto').setItemsFichas('');
        });

        this.traerPaises();

        this.getField('tipo_documento_id').setOnChange(() => { this.getField('tipo_documento_id').getValue() !== '' ? this.getField('tipo_documento_id').setError(false, '') : this.getField('tipo_documento_id').valid() });
        this.getField('nombre_tipo_doc').setOnChange(this.tipoDocumento);
        this.getField('nit').setOnBlur(this.calcularDigitoNit);

        this.getField('nom1').setOnChange(this.concatenarNombre);
        this.getField('nom2').setOnChange(this.concatenarNombre);
        this.getField('ape1').setOnChange(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
        this.getField('ape2').setOnChange(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
        this.getField('nombre').setOnChange(() => { this.getField('nombre').getValue().length >= 1 ? this.getField('nombre').setError(false, "") : this.getField('nombre').valid(); });

        this.getField('numero').setOnChange(() => { this.getField('numero').valid() ? this.getField('btn_cotizaciones').setDisabled(false) : this.getField('btn_cotizaciones').setDisabled(true); this.itemsPedido = []; this.arrayFinalIntegrarItemsCotizacion = []; this.preciosMostradorProductosCrear = ''; this.nitsxSedesIdMostradorProductosCrear = 0; this.contadorIntegrar = 0; });
        this.getField('btn_cotizaciones').setClick(this.traerInformacionPedidos);
        this.getField('campo_carga_pedidos_traertodopornumero').setOnChange(this.traertodopornumero);
        this.getField('campo_carga_most_productosporcliente').setOnChange(this.clientesPendientes);
        this.getField('modal_items_cotizacion').setCloseButton(() => { this.getField('numero').setValue(''); this.getField('numero').setError(false, ''); this.itemsPedido = []; this.arrayFinalIntegrarItemsCotizacion = []; this.preciosMostradorProductosCrear = ''; this.nitsxSedesIdMostradorProductosCrear = 0; this.contadorIntegrar = 0; this.estadoBodegasExistencias = false; this.getField('btn_integrar').setDisabled(false); });
        this.getField('btn_integrar').setClick(this.validarItemsCotizacionIntegrar);

        this.getField("bt_buscar_por_nombre_marca").setClick(this.buscarMarca);
        this.getField("nombre_filtrar").setOnChange(this.filtrarNombre);
    }
    
    filtrarNombre(){
        if(this.getField("nombre_filtrar").getValue() === ""){
            this.getField("nombre_filtrar").setError(false,"");
            this.getField("nombre_filtrar").setDisabled(true);
            this.getField("btnFiltrar").setDisabled(true);
            this.getField('tablaProductos').toggle(false);
            let datos = { datos:{
                cliente_id: this.cliente_id,
                bodega: this.saldo_bodegas_productos,
                numero_pedido: this.codigoConsecutivo,
                codigo_sucursal: this.codigo_sucursal,
                estado:"P"
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporcliente', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successUploadTable,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }else{
            this.getField("nombre_filtrar").valid();
        }
    }

    manejaRemision(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'most-facturacion', operacion: 'remision', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successManejaRemision,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });

        let datos2={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'manejaconteo', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos2,
            success: this.successManejaConteo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successManejaRemision(data){
        if(data.estado_p === 200){
            if(data.data[0].maneja_remision === "S"){
                this.maneja_remision = true;
                this.getField("producto_remision").setVisible(true);
                this.getField("producto").setVisible(false);
                this.getField("observacion").setValue(".");
                this.getField("observacion").setVisible(true);
                this.getField("cliente_remisiona").setVisible(true);
            }else{
                this.getField("observacion").setVisible(false);
                this.getField("observacion").setValue(".");
                this.getField("producto_remision").setValue("N");
            }
        }
    }

    VerMargen(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'vermargen', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successVerMargen,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successVerMargen(data){
        if(data.estado_p === 200){
            this.ver_margen = data.data[0].ver_margen;
        }else { 
            this.alertGeneral.toggle(true,data.data.mensaje,"error");
        } 
    }

    successManejaConteo(data){
        if(data.estado_p === 200){
            if(data.data[0].maneja_conteo === "S"){
                this.maneja_conteo = true;                
            }else{
                this.maneja_conteo = false;
            }
        }
    }

    nuevoPedidosPendientes() {
        this.arrayNuevo = [];
        let rem = this.getField("factura_remision").getValue();
        if(this.getField('cliente').getValue() !== '')
            this.getField('cliente').setValue('');
        this.getField('sede_cliente').setValue('');
        this.getField('nombre_cliente').setValue('');
        this.getField('razon_social').setValue('');
        this.getField('tercero_id_sede').setValue('');
        this.getField("factura_remision").setValue(rem);

        let datos={ datos: {
            codigo_sucursal:this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'traerencabezadopedidos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successNuevoPendientes,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successNuevoPendientes(data) { 
        this.getField('cliente').setError(false,'');
        if (data.estado_p === 200) {

            this.arrayPendientes = data.data; // almacenar el valor del primer servicio en un array global

            let datos={ datos: {
                codigo_sucursal:this.codigo_sucursal
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'traeritemspedidos', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.susccessRespuestaSegundoServ,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.getField('tabla_terceros').toggle(false);
            this.getField('tablaClientes').toggle(false);
            this.getField('pedidos_pendientes').setDisabled(false);
        }
    }

    susccessRespuestaSegundoServ(data) {
        if (data.estado_p === 200) {

            this.arrayPendientes.forEach((item) => {

                let objetoNuevo = {
                    numero_pedido: item.numero_pedido,
                    nombre_cliente: item.nombre,
                    documento: item.nit,
                    sede: item.sede,
                    id: item.nitsx_sedes_id,
                    total: item.sum,
                    tipo_pedido: item.tipo_pedido
                }

                data.data.forEach((item2) => {
                    if(item.nitsx_sedes_id === item2.nitsx_sedes_id){
                        objetoNuevo['codigo_usuario'] = item2.codigo;
                        objetoNuevo['fecha'] = item2.fecha_sistema;
                    }
                });
                this.arrayNuevo.push(objetoNuevo);

            });
            let data1 = {
                "data" : this.arrayNuevo,
                "estado_p": data.estado_p
            };

            this.mostrarClientes(data1);

        }else{
            this.getField('tabla_terceros').toggle(false);
            this.getField('tablaClientes').toggle(false);
            this.getField('pedidos_pendientes').setDisabled(false);
        }
    }

    mostrarClientes(data) {

        this.getField('pedidos_pendientes').setDisabled(false);
        
        this.contDevolver = 0;
        this.arrayIdsEliminar = 0;
        this.getField('producto').setError(false, '');

        this.getField('confirmModalCustom').toggle(false);
        if ((data.estado_p === 200 ) && (data.data[0].total > 0)) { //&& (data.data[0].total > 0 || data[0].total > 0)
            this.gridOptionsTablaTerceros['rowData'] = [];
            this.getField('tabla_terceros').initData(this.gridOptionsTablaTerceros);
            this.getField('tabla_terceros').toggle(false);

            this.getField('modal_items_cotizacion').handleClose(); // Cuando el Usuario se trae los items cotización al momento de integrarlos, se refresca la tabla de la pantalla principal y una vez se cargue la tabla con los nuevos registros, se debe cerrar la modal de intregar items cotización.
            let configCell = new Map();
            configCell.set('productos', { cellRenderer: this.setButtonDelete, width: 132, sortable: false, filter: false, field: 'productos' });
            configCell.set('numero_pedido', { cellRenderer: this.selectionNumeroPedido, cellStyle: this.selectionRegistro2,width: 200, field: 'numero_pedido' });           
            configCell.set('cerrar_pedido', { cellRenderer: this.setButtonCerrarPedido, width: 132, sortable: false, filter: false, field: 'cerrar_pedido' });
            configCell.set('imprimir_pedido', { cellRenderer: this.setButtonImprimirPedido, width: 132, sortable: false, filter: false, field: 'imprimir_pedido' });
            configCell.set('codigo_usuario', { cellRenderer: this.selectionRegistro, cellStyle: this.selectionRegistro2,width: 200, field: 'codigo_usuario' });
            configCell.set('nombre_cliente', { cellRenderer: this.seleccionCliente, cellStyle: this.selectionRegistro2,width: 233, field: 'nombre_cliente' });
            configCell.set('documento', { cellRenderer: this.seleccionDocumento, cellStyle: this.selectionRegistro2,width: 131, field: 'documento' });
            configCell.set('sede', { cellRenderer: this.seleccionSede, cellStyle: this.selectionRegistro2,width: 90, field: 'sede' });
            configCell.set('fecha', { cellRenderer: this.seleccionFecha, cellStyle: this.selectionRegistro2,width: 110, field: 'fecha' });
            configCell.set('total', { cellRenderer: this.seleccionTotal, cellStyle: this.selectionRegistro2,width: 200, field: 'total' });
            configCell.set('tipo_pedido', { cellRenderer: this.seleccionTipoPedido, cellStyle: this.selectionRegistro2,width: 200, field: 'tipo_pedido' });
            configCell.set('devolver_todo', { cellRenderer: this.setButtonDevolver, width: 110, sortable: false, filter: false, field: 'devolver_todo' });
            this.gridOptions['rowData'] = data.data;
            this.getField('tablaClientes').initData(this.gridOptions, configCell);
        } else if(data.estado_p === 200 && data.data[0].total === 0 ){
            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').initData(this.gridOptions);
            this.getField('tablaClientes').toggle(false);

            this.getField('modal_items_cotizacion').handleClose(); // Cuando el Usuario se trae los items cotización al momento de integrarlos, se refresca la tabla de la pantalla principal y una vez se cargue la tabla con los nuevos registros, se debe cerrar la modal de intregar items cotización.
            let configCell = new Map();
            configCell.set('productos', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'productos' });
            configCell.set('total', {  cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.total) }, type: 'rightAligned' });
            configCell.set('sede', { width: 90, field: 'sede' });
            configCell.set('documento', { width: 120, field: 'documento' });
            this.gridOptionsTablaTerceros['rowData'] = data.data;
            this.getField('tabla_terceros').initData(this.gridOptionsTablaTerceros, configCell);
        } else if (data.estado_p === 404 || data.length === 0) {
            this.alertGeneral.toggle(true, 'No hay pedidos pendientes', "error");
            this.getField('tablaClientes').toggle(false);
            this.getField('tabla_terceros').toggle(false);
        }
        this.getField("cliente").setError(false,"");
    }

    selectionRegistro2(props){
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo)
            return {height: '100%', width: '200px', position: 'fixed', paddingRight: '0%', paddingLeft: '0%', paddingTop: '0%',fontSize: '0.875rem', textAlign:'left', color: '#0000ff', fontWeight: 'bold', backgroundColor: '#B7E4FF'}
        else 
            return {height: '100%', paddingLeft: '0%', paddingTop: '0%', paddingRight: '0%', fontSize: '0.875rem', textAlign:'left'}
    }

    /**
     * Método implementado para renderizar los botones de PRODUCTOS en la tabla principal.
     * @param {Obj} props propiedades de la columna seleccionada de la tabla
     */
    setButtonDelete(props) {
        let button = document.createElement("input");
        button.onclick = () => this.mostrarProductos(props);
        button.setAttribute("id", 'button_delete_' + props.id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Productos");
        return this.createElementJaivana(button);
    }

        /**
     * @param {*} props 
     * @description metodo que muestra los productos en detalle que tiene agragados el cliente seleccionado
     */
    mostrarProductos(props) {
        this.getField('tablaProductos').toggle(false);
        this.registroSeleccionado = props.data.documento;
        this.codigo_usuario = props.data.codigo_usuario;

        this.codigoConsecutivo = props.data.numero_pedido;
        this.totalCantidadProductos = 0;
        this.precio_cliente = props.data.precios;
        this.id_cliente = props.data.id;
        this.cliente_id = props.data.id;
        this.getField('precios_cliente').setValue(props.data.precios);
        if (this.getField('listaProductos').setItemsFichas)
            this.getField('listaProductos').setItemsFichas([]);
        this.getField("total_producto").setValue(0);

        let datos={ datos: {
            id: props.data.id,   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            numero_pedido: this.codigoConsecutivo,
            codigo_sucursal:this.codigo_sucursal,
            estado:"P"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'buscarid', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDatosClientes2,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successDatosClientes2(data) {

        if (data.estado_p === 200) {
            let dataArray = [];
            dataArray.push(data.data[0])
            this.dataCliente = dataArray;
            this.getField('cliente').setValue(this.dataCliente[0].documento);
            this.getField('sede_cliente').setValue(this.dataCliente[0].sede);
            this.getField('nombre_cliente').setValue(this.dataCliente[0].nombre_cliente);
            this.nitCliente = this.dataCliente.nit;
            this.sedeCliente = this.dataCliente.sede;
            this.preciosCliente = this.dataCliente.precios;
            this.cliente_id = data.data[0].id;

            this.maneja_remision_producto = this.dataCliente[0].factura_remision;
            this.getField("factura_remision").setValue(this.dataCliente[0].factura_remision);

            let datos={ datos: {
                cliente_id: this.cliente_id,
                codigo_sucursal:this.codigo_sucursal,
                bodega: this.saldo_bodegas_productos,
                numero_pedido: this.codigoConsecutivo,
                estado:"P"
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporcliente', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successProductosClientes,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.dataCliente = [];
        }
    }

    successProductosClientes(data) {
        this.getField("nombre_filtrar").setError(false,"");
        let datos = '';
        if (data.estado_p === 200) {
            datos = this.case === 1 ? data.ultimo : this.case === null ? data.data : '';
            if (data.ultimo) {
                if (data.data[0].cuantos > 0)
                    this.agregarDescuento = true;
                else
                    this.agregarDescuento = false;
            } else {
                this.agregarDescuento = false;
            }
        }
        this.getField('modalProducto').handleClickOpen(() => {
            this.getField('tablaProductos').toggle(false);
            this.getField('btnAgregarProducto').setDisabled(false);
            this.getField('btnAgregarProducto').setClick(this.guardarProducto);
            if (datos.length > 0) {
                this.tipoPedido = datos[0].tipo_pedido;
                this.tipoDespacho = datos[0].tipo_despacho;
                this.getField('nombre_filtrar').setDisabled(false);
                this.getField('btnFiltrar').setDisabled(false);
                this.recuperarCodigosArray(datos);
            }else{
                if (this.getField('listaProductos').setItemsFichas)
                    this.getField('listaProductos').setItemsFichas(this.dataCliente);
            }
        });
        this.getField('btnAgregarProducto').setDisabled(true);
    }

    recuperarCodigosArray(datos) {        
        let modifica_descuento={};
        let descuento = [];
        modifica_descuento={
            "modificar_descuento": datos[0].modificar_descuento,
        }
        descuento.push(modifica_descuento);
        let nuevoArray = datos;

        this.initModalProductos(nuevoArray,descuento)
    }

    initModalProductos(datos,descuento) {
        this.getField('nit_buscar').setValue(0);
        let data = datos?.ultimo ?? datos;
        let datadescuento = descuento?.ultimo ?? descuento;
        
        if (data !== undefined && data !== null && data.length > 0) {
            this.getField('nombre_filtrar').setDisabled(false);
            this.getField('btnFiltrar').setDisabled(false);
            this.getField('tablaProductos').toggle(true);
            this.totalCantidadProductos = 0;
            for (let dato in data) {
                this.totalCantidadProductos += parseFloat(data[dato]['total']);
            }
            this.gridOptionsProducto['rowData'] = [];//
            this.getField('tablaProductos').initData(this.gridOptionsProducto);//
            let configCell = new Map();
            configCell.set('total', { cellRenderer: this.setButtonValorActual, field: 'total', type: 'rightAligned' });
            configCell.set('cantidad', {  cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.cantidad) }, type: 'rightAligned' });
            configCell.set('precio', {  cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.precio) }, type: 'rightAligned' });
            
            if(this.ver_margen === 'S'){
                configCell.set('margen', { cellRenderer: function (props) { return props.data.margen.toFixed(2)}});
            }else{
                configCell.set('margen', { cellRenderer: function (props) {return 0}});
            }
            
            if ((datos.data && datos.data[0].cuantos) || (this.agregarDescuento))
                configCell.set('descuento', { cellRenderer: function (props) { return props.data.descuento }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.descuentoChange, sortable: true, filter: false, field: 'descuento' });
            if (datadescuento[0].modificar_descuento) {
                configCell.set('descuento', { cellRenderer: function (props) { return props.data.descuento }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.descuentoChange, sortable: false, filter: false, field: 'descuento' });
            }
            configCell.set('accion', { cellRenderer: this.setButtonDeleteProducto, sortable: false, filter: false, field: 'acción' });
            this.gridOptionsProducto['rowData'] = data;
            if (this.getField('tablaProductos').initData)
                this.getField('tablaProductos').initData(this.gridOptionsProducto, configCell);

            if (isNaN(this.totalCantidadProductos)) {
                if (this.getField('total_producto').setValue)
                    this.getField('total_producto').setValue(0);
            } else {
                if (this.getField('total_producto').setValue)
                    this.getField('total_producto').setValue(this.totalCantidadProductos.toFixed(2));
            }
            if (this.dataCliente[0] !== undefined)
                this.dataCliente[0]['total'] = '' + this.currencyFormatterGeneral(this.dataCliente[0]['total']);

        } else{
            this.getField('nombre_filtrar').setDisabled(true);
            this.getField('btnFiltrar').setDisabled(true);
            this.getField('total_producto').setValue(0);
        }
        if (this.getField('listaProductos').setItemsFichas)
            this.getField('listaProductos').setItemsFichas(this.dataCliente);

        this.case = null;
    }

    setButtonValorActual(props) {
        let total = props.data.total;
        return '' + this.currencyFormatterGeneral(total);
    }
    
    setButtonCerrarPedido(props){
        let button = document.createElement("input");
        button.onclick = () => this.cerrarPedido(props);
        button.setAttribute("id", 'button_close_' + props.id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Cerrar Pedido");
        return this.createElementJaivana(button);
    }

    cerrarPedido(props){
        if(this.maneja_conteo === false){            
            this.getField('confirmModalCustom').setTitleAndContent('Cerrar Pedido', `¿Desea cerrar el pedido?`)
            this.getField('confirmModalCustom').setClickDialog(() => {this.confirmCerrarPedido(props)});
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel("Cancelar")
            this.getField('confirmModalCustom').toggle(true);
        }else{
            this.getField('confirmModalCustom').setTitleAndContent('CONTAR PEDIDO O CERRAR PEDIDO', '¿Desea enviar el pedido a conteo o lo desea enviar a caja?');
            this.getField('confirmModalCustom').setClickDialog(() => { 
                this.getField('confirmModalCustom').toggle(false);
                this.confirmCerrarPedido(props, true);
            });

            this.getField("confirmModalCustom").setClickCancelDialog (()=>{
                this.getField('confirmModalCustom').toggle(false);   
                this.confirmCerrarPedido(props, false);             
            });

            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("ENVIAR A CONTEO"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel("ENVIAR A CAJA")
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    confirmCerrarPedido(props, viajaconteo){
        if(this.maneja_conteo === false){
            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').toggle(false);
            this.getField('confirmModalCustom').toggle(false);

            let datos={ datos: {
                numero_pedido: parseInt(props.data.numero_pedido),
                codigo_sucursal: JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
                estado:"A"
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'cerrarpedido', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCerrarPedido,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        } else if (viajaconteo === false){
            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').toggle(false);
            this.getField('confirmModalCustom').toggle(false);
            
            let datos={ datos: {
                numero_pedido: parseInt(props.data.numero_pedido),
                codigo_sucursal: JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
                estado:"A"
            }};

            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'cerrarpedido', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCerrarPedido,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.confirmContarPedido(props);
        }
    }

    successCerrarPedido(data){
        this.nuevoPedidosPendientes();
    }

    confirmContarPedido(props){
        this.gridOptions['rowData'] = [];
        this.getField('tablaClientes').toggle(false);
        this.getField('confirmModalCustom').toggle(false);

        let datos={ datos: {
            numero_pedido: parseInt(props.data.numero_pedido),
            codigo_sucursal: JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
            estado:"C"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'cerrarpedido', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successContarPedido,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successContarPedido(data){
        this.nuevoPedidosPendientes();
    }

    setButtonImprimirPedido(props){
        let button = document.createElement("input");
        button.onclick = () => this.ImprimirPedido(props);
        button.setAttribute("id", 'button_close_' + props.id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Imprimir Pedido");
        return this.createElementJaivana(button);
    }

    ImprimirPedido(props){
        this.datoCliente = props.data;
        this.TraerDatosClienteFinal();
        this.getField('modalDatosCliente').handleClickOpen();
        this.getField("correo_usuario").setError(false,"");
        this.getField("numero_telefono").setError(false,"");
    }

    TraerDatosClienteFinal(){
        this.mostrarMensajeGenerando(); 

        let datos={ datos: {
            nit: this.datoCliente.documento,   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            sede: this.datoCliente.sede
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'retornardatos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successTraerDatosClienteFinal,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successTraerDatosClienteFinal(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) { // si estado_p === 200, se debe mostrar el confirm dialog para agregar el producto
            if(data.data[0].email_factura_electronica === null){
                this.getField("correo_usuario").setValue('noreply@gmail.com');
            }else{
                this.getField("correo_usuario").setValue(data.data[0].email_factura_electronica);
            }
            this.getField("numero_telefono").setValue(data.data[0].telefonos);
        } else { 
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true,data.data.mensaje,"error");
        }
    }

    /**
     * 
     * @param {*} props 
     * @description este metodo renderiza un boton para devolver los productos en la tabla de pedidos
     * @returns elemento html (un boton)
     */
    setButtonDevolver(props) {
        let button = document.createElement("input");
        button.onclick = () => this.confirmDevolverTodo(props);
        button.setAttribute("id", 'button_delete_' + props.id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Devolver todo");

        return this.createElementJaivana(button);
    }

    confirmDevolverTodo(props) {
        this.ids = props.data.id;
        this.codigoConsecutivo = props.data.numero_pedido;

        this.getField('confirmModalCustom').setTitleAndContent('Se eliminarán todos los productos del pedido', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.consultarItemsDevolver);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setButtonCancel("Cancelar")
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);

    }

        /**
     * 
     * @param {*} props 
     * @description metodo que le da la funcionalidad al boton devolver todo,
     * aqui se hace la consulta de cuantos items tiene el pedido del cliente y se almacena en un 
     * array para luego eliminarlos de los pedidos pendientes uno a uno
     */
    consultarItemsDevolver() {

        this.getField('btnNuevoFerri').setDisabled(true);
        this.getField('btnBuscarProductoBodegas').setDisabled(true);
        this.getField('pedidos_pendientes').setDisabled(true);
        this.getField('cliente').setDisabled(true);

        let datos={ datos: {
            cliente_id: this.ids,   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            bodega: this.saldo_bodegas_productos,
            codigo_sucursal:this.codigo_sucursal,
            numero_pedido: this.codigoConsecutivo,
            estado:"P"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporcliente', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successItemsDevolver,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    /**
     * @param {*} data
     * @description metodo que captura la respuesta del servicio y la procesa
     *  
     */
    successItemsDevolver(data) {
        if(data.estado_p === 200){
            this.arrayIdsEliminar = this.case === 2 ? data.ultimo : data.data;

            this.arrayIdsEliminar.map(item => {
                this.eliminarItemsPedido(item.id);
                return null;
            });
        }else{
            let configCell = new Map();
            this.arrayNuevo = [];
            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').initData(this.gridOptions, configCell);
            this.getField('tablaClientes').toggle(false);
            this.registroSeleccionado = '';
            this.nuevoPedidosPendientes();
            this.getField('confirmModalCustom').toggle(false);
            this.getField('btnNuevoFerri').setDisabled(false);
            this.getField('btnBuscarProductoBodegas').setDisabled(false);
            this.getField('pedidos_pendientes').setDisabled(false);
            this.getField('cliente').setDisabled(false);
        }
    }

    eliminarItemsPedido(id) {

        let datos={ datos: {
            id: id,   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            estado: "P"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'eliminar', operacion_tipo: 'eliminar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successEliminarItems,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successEliminarItems(data) {
        this.contDevolver += 1;
        this.case = null;

        this.getField('btnNuevoFerri').setDisabled(false);
        this.getField('btnBuscarProductoBodegas').setDisabled(false);
        this.getField('pedidos_pendientes').setDisabled(false);
        this.getField('cliente').setDisabled(false);

        if (this.contDevolver === this.arrayIdsEliminar.length) {
            let configCell = new Map();
            this.arrayNuevo = [];
            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').initData(this.gridOptions, configCell);
            this.getField('tablaClientes').toggle(false);
            this.registroSeleccionado = '';
            this.nuevoPedidosPendientes();
            this.getField('confirmModalCustom').toggle(false);

        }
    }

    selectionNumeroPedido(props) {
        let codigo_usuario = props.data.numero_pedido;
        if (props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width: 200px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = codigo_usuario;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = codigo_usuario;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistro(props) {
        let codigo_usuario = props.data.codigo_usuario;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width: 200px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = codigo_usuario;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = codigo_usuario;
            return this.createElementJaivana(div);
        }
    }

    seleccionCliente(props) {
        let nombre_cliente = props.data.nombre_cliente;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width: 233px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = nombre_cliente;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = nombre_cliente;
            return this.createElementJaivana(div);
        }
    }

    seleccionDocumento(props) {
        let documento = props.data.documento;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width: 131px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = documento;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = documento;
            return this.createElementJaivana(div);
        }
    }

    seleccionSede(props) {
        let sede = props.data.sede;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width: 90px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = sede;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = sede;
            return this.createElementJaivana(div);
        }
    }

    seleccionFecha(props) {
        let fecha = props.data.fecha;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width: 110px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = fecha;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = fecha;
            return this.createElementJaivana(div);
        }
    }

    seleccionTotal(props) {
        let total = props.data.total;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width: 200px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = '' + this.currencyFormatterGeneral(total);

            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = '' + this.currencyFormatterGeneral(total);//total;
            return this.createElementJaivana(div);
        }
    }

    seleccionTipoPedido(props) {
        let tipo_pedido = props.data.tipo_pedido;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width: 200px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = tipo_pedido;

            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = tipo_pedido;//total;
            return this.createElementJaivana(div);
        }
    }

    onSelectionChanged() {
        this.filaSeleccionadaGrid = this.gridOptionsProductos.api.getSelectedRows();
        this.getField('producto').setValue(this.filaSeleccionadaGrid[0].codigo);
        this.getField('nombre_marca').setValue('.');
        this.getField('marca').setValue(this.filaSeleccionadaGrid[0].marca);
        this.getField('tarifa_iva').setValue(this.filaSeleccionadaGrid[0].tarifa_iva);
        this.getField('precio').setValue(this.filaSeleccionadaGrid[0].precio);
        this.getField('precio_mas_iva').setValue(this.filaSeleccionadaGrid[0].precio_mas_iva);
        this.getField('referencia').setValue(this.filaSeleccionadaGrid[0].referencia);
        this.getField('presenta').setValue(this.filaSeleccionadaGrid[0].presenta);
        this.getField('presenta').setValue(this.filaSeleccionadaGrid[0].presenta);
        this.getField('existencia').setValue(this.filaSeleccionadaGrid[0].existencia);
        this.getField('articulo_id').setValue(this.filaSeleccionadaGrid[0].id);
        this.getField('precio_incluye_iva').setValue(this.filaSeleccionadaGrid[0].precio_incluye_iva);//   
        this.getField('nombre_producto').setValue(this.filaSeleccionadaGrid[0].nombre);

        this.getField('modal_productos').handleClose();
    }

    successTraerConsecutivo(data){
        if(data.estado_p === 200){
            this.codigoConsecutivo = data.data[0].numero;
            this.buscarClientesPorNit();
        }else{
            this.alertGeneral.toggle(true, 'Error la sucursal '+this.codigo_sucursal+' no tiene un consecutivo configurado.', 'error');
        }
    }

    buscarClientesPorNit() {
        let terceroIdSede =  this.getField("tercero_id_sede1").getValue();
        if(terceroIdSede !== '' && terceroIdSede !== ' ' && terceroIdSede !== undefined){
            this.copiaData = [];
            if (this.getField('tercero_id_sede1').getValue() !== '' && this.getField('tercero_id_sede1').getValue() !== '.' && 
            this.getField('tercero_id_sede1').getValue() >= 1) {

                let datos={ datos: {
                    id: this.getField('tercero_id_sede1').getValue(),
                    numero_pedido: this.codigoConsecutivo,
                    codigo_sucursal: this.codigo_sucursal,
                    estado:"P"
                }};
                this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'buscarid', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.traerDatosCliente,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }
    }

    traerDatosCliente(data){
        this.getField("modalNuevoPedido").handleClose();
        if(data.estado_p === 200){
            let respuesta = {};
            let objetoGraficar = {};
            if (data.data[0].total_items > 0) {
                objetoGraficar = {
                    "numero_pedido":this.codigoConsecutivo,
                    "codigo_usuario":JSON.parse(localStorage.getItem('res')).codigo,
                    "documento":data.data[0].documento,
                    "fecha":data.data[0].fecha_sistema,
                    "id":data.data[0].id,
                    "nombre_cliente":data.data[0].nombre_cliente,
                    "sede":data.data[0].sede,
                    "tipo_pedido":data.data[0].tipo_pedido,
                    "total":data.data[0].total
                };
            }else{
                objetoGraficar = {
                    "numero_pedido":this.codigoConsecutivo,
                    "codigo_usuario":JSON.parse(localStorage.getItem('res')).codigo,
                    "documento":data.data[0].documento,
                    "nombre_cliente":data.data[0].nombre_cliente,
                    "id":data.data[0].id,
                    "sede":data.data[0].sede,
                    "total":data.data[0].total
                };
            }
    
            respuesta = {
                "data":[objetoGraficar],
                "model":[objetoGraficar],
                "estado_p": data.estado_p
            }
            

            this.mostrarClientes(respuesta);
        }
    }

    habilitadoEnviarMSM(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'msm_habilitado', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successMsm,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successMsm(data){
        if(data.estado_p === 200){
            if(data.data[0].habilitado === 'S'){
                this.habilitadoMsm = true;
            }
        }
    }
    
    ActualizarDatos(){
        this.mostrarMensajeGenerando();         
        let datos={ datos: {
            nit: this.datoCliente.documento,   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            sede: this.datoCliente.sede,
            email_factura_electronica:this.getField("correo_usuario").getValue(),
            telefonos:this.getField("numero_telefono").getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'modificadatos', operacion_tipo: 'modificar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.successActualizar,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successActualizar(data){
        if (data.estado_p === 200) { // si estado_p === 200, se debe mostrar el confirm dialog para agregar el producto
            this.traerDatos();
        } else { // sino se debe mostrar el dialog de descuento no permitido
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true,data.data.mensaje,"error");
        } 
    }

    traerDatos(){
        
        let datos={ datos: {
            id: this.datoCliente.id,   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            numero_pedido: this.datoCliente.numero_pedido,
            codigo_sucursal:this.codigo_sucursal,
            estado:"P"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'buscarid', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successProductosPdf,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successProductosPdf(data){
        if(data.estado_p === 200){
            this.cliente_id=data.data[0].id;
            this.total_cliente=data.data[0].total;
            if(this.getField("correo_usuario").getValue() !== "" && this.getField("numero_telefono").getValue() !== ""){
                if(this.habilitadoMsm){
                    this.enviarsms();
                    this.enviarCorreo();
                    this.getField("modalDatosCliente").handleClose();
                }else{
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No esta habilitado para enviar mensajes de texto. Solo se permite envio de correo. ¿Desea continuar?`);
                    this.getField('confirmModalCustom').setClickDialog(()=>{
                        this.enviarCorreo();
                        this.getField('confirmModalCustom').toggle(false);
                        this.getField("modalDatosCliente").handleClose();
                    });  
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("Ok"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField("confirmModalCustom").setButtonCancel("Cancelar")
                    this.getField('confirmModalCustom').toggle(true);
                }
            }
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    enviarCorreo(){           
        let datos={ datos: {
            sucursal_ingreso:JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
            operacion:"pdf",
            email:this.getField('correo_usuario').getValue(),
            radio_correo:"S",
            cliente_id:this.cliente_id,
            bodega: this.saldo_bodegas_productos,
            numero_pedido: this.datoCliente.numero_pedido,
            estado:"P",
            total:this.datoCliente.total
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'enviarpdfcorreo', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successEnviarCorreo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.generarPdfTabla();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla(){
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let body={
            sucursal_ingreso:JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
            operacion:"pdf",
            email:this.getField('correo_usuario').getValue(),
            radio_correo:"N",
            cliente_id:this.cliente_id,
            bodega: this.saldo_bodegas_productos,
            numero_pedido: this.datoCliente.numero_pedido,
            estado:"P",
            total:this.datoCliente.total
        };

        const myJSON = JSON.stringify(body);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"most-clientes","operacion":"enviarpdfcorreo","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], 'listaClientesPedidos.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();                
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    enviarsms(){   
        let datos={ datos: {
            sucursal_ingreso:JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
            cliente_id:this.cliente_id,
            bodega: this.saldo_bodegas_productos,
            numero_pedido: this.datoCliente.numero_pedido,
            estado:"P",
            total:this.datoCliente.total,
            telefono:this.getField("numero_telefono").getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'enviarsms', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successEnviarSms,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successEnviarSms(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    guardarCliente() {
        if (this.getField('nit').valid() && this.getField('verifica').valid() && this.getField('tipo_documento_id').valid() &&
            this.getField('nombre').valid() && this.getField('paises_id').valid() && this.getField('departamento_geografico_id').valid() &&
            this.getField('ciudad_id').valid() && this.getField('direccion').valid() && this.getField('telefonos').valid() &&
            this.getField('email').valid() && this.getField('codigo_vendedor').valid() && this.getField('nombre_vendedor').valid() &&
            this.getField('nom1').valid() && this.getField('nom2').valid() && this.getField('ape1').valid() && this.getField('ape2').valid() &&
            this.getField('fecha_nacimiento').valid() && this.getField('sexo').valid() && this.getField('tipo_persona').valid()) {

            if (this.maneja_remision){
                if (this.getField('cliente_remisiona').getValue() !== '' && this.getField('cliente_remisiona').getValue() !== "" && this.getField('cliente_remisiona').getValue() !== undefined && this.getField('cliente_remisiona').getValue() !== null){

                    if (this.getField('nombre_tipo_doc').getValue() === 'NIT') {                       
                        
                        let datos={ datos: {
                            nit: this.getField('nit').getValue(),
                            nombre: this.getField('nombre').getValue(),
                            telefonos: this.getField('telefonos').getValue(),
                            email: this.getField('email').getValue(),
                            ciudad_id: this.getField('ciudad_id').getValue(),
                            departamento_geografico_id: this.getField('departamento_geografico_id').getValue(),
                            paises_id: this.getField('paises_id').getValue(),
                            direccion: this.getField('direccion').getValue(),
                            tipo_documento_id: this.getField('tipo_documento_id').getValue(),
                            verifica: this.getField('verifica').getValue(),
                            codigo_vendedor: this.getField('codigo_vendedor').getValue(),
                            nom1: this.getField('nom1').getValue(),
                            nom2: this.getField('nom2').getValue(),
                            ape1: this.getField('ape1').getValue(),
                            ape2: this.getField('ape2').getValue(),
                            fecha_nacimiento: this.getField('fecha_nacimiento').getValue(),
                            sexo: this.getField('sexo').getValue(),
                            tipo_persona: this.getField('tipo_persona').getValue(),
                            autoretenedor: this.getField('tipo_persona').getValue() === 'J' ? 'S' : 'N',
                            cliente_remisiona: this.getField('cliente_remisiona').getValue(),
                        }};

                        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'crear', operacion_tipo: 'crear' };
                        this.service.send({
                            endpoint: this.constant.formConfiguration(),
                            method:'POST',
                            body: datos,
                            success: this.successGuardarCliente,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            //showMessage: false
                        });
                    
                    }

                    if (this.getField('nombre_tipo_doc').getValue() !== 'NIT') {
                        if ((this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
                            this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '')) {
                            
                            let datos={ datos: {
                                nit: this.getField('nit').getValue(),
                                nombre: this.getField('nombre').getValue(),
                                telefonos: this.getField('telefonos').getValue(),
                                email: this.getField('email').getValue(),
                                ciudad_id: this.getField('ciudad_id').getValue(),
                                departamento_geografico_id: this.getField('departamento_geografico_id').getValue(),
                                paises_id: this.getField('paises_id').getValue(),
                                direccion: this.getField('direccion').getValue(),
                                tipo_documento_id: this.getField('tipo_documento_id').getValue(),
                                verifica: this.getField('verifica').getValue(),
                                codigo_vendedor: this.getField('codigo_vendedor').getValue(),
                                nom1: this.getField('nom1').getValue(),
                                nom2: this.getField('nom2').getValue(),
                                ape1: this.getField('ape1').getValue(),
                                ape2: this.getField('ape2').getValue(),
                                fecha_nacimiento: this.getField('fecha_nacimiento').getValue(),
                                sexo: this.getField('sexo').getValue(),
                                tipo_persona: this.getField('tipo_persona').getValue(),
                                autoretenedor: this.getField('tipo_persona').getValue() === 'J' ? 'S' : 'N',
                                cliente_remisiona: this.getField('cliente_remisiona').getValue(),
                            }};

                            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'crear', operacion_tipo: 'crear' };
                            this.service.send({
                                endpoint: this.constant.formConfiguration(),
                                method:'POST',
                                body: datos,
                                success: this.successGuardarCliente,
                                error: this.error_,
                                general: this.generalFormatPmv,
                                //showMessage: false
                            });

                        } else {
                            this.getField('confirmModalCustom').setTitleAndContent('Error', `Mínimo debe ir un nombre con un apellido`)
                            this.getField('confirmModalCustom').setClickDialog(() => {
                                this.getField('confirmModalCustom').toggle(false);
                                this.getField('ape1').setError(true, '* Este campo es requerido.');
                                this.getField('ape2').setError(true, '* Este campo es requerido.');
                            });
                            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                            this.getField('confirmModalCustom').toggle(true);
                        }
                    }
                } else {
                    this.getField('cliente_remisiona').setError(true, '* Este campo es requerido.');
                }
            }else {

                if (this.getField('nombre_tipo_doc').getValue() === 'NIT') {
                    
                    let datos={ datos: {
                        nit: this.getField('nit').getValue(),
                        nombre: this.getField('nombre').getValue(),
                        telefonos: this.getField('telefonos').getValue(),
                        email: this.getField('email').getValue(),
                        ciudad_id: this.getField('ciudad_id').getValue(),
                        departamento_geografico_id: this.getField('departamento_geografico_id').getValue(),
                        paises_id: this.getField('paises_id').getValue(),
                        direccion: this.getField('direccion').getValue(),
                        tipo_documento_id: this.getField('tipo_documento_id').getValue(),
                        verifica: this.getField('verifica').getValue(),
                        codigo_vendedor: this.getField('codigo_vendedor').getValue(),
                        nom1: this.getField('nom1').getValue(),
                        nom2: this.getField('nom2').getValue(),
                        ape1: this.getField('ape1').getValue(),
                        ape2: this.getField('ape2').getValue(),
                        fecha_nacimiento: this.getField('fecha_nacimiento').getValue(),
                        sexo: this.getField('sexo').getValue(),
                        tipo_persona: this.getField('tipo_persona').getValue(),
                        autoretenedor: this.getField('tipo_persona').getValue() === 'J' ? 'S' : 'N',
                        cliente_remisiona: 'N',
                    }};
                    this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'crear', operacion_tipo: 'crear' };
                    this.service.send({
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successGuardarCliente,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        //showMessage: false
                    });
                }

                if (this.getField('nombre_tipo_doc').getValue() !== 'NIT') {
                    if ((this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
                        this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '') ||
                        (this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
                            this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
                        (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
                            this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
                        (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
                            this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '')) {
                        
                        let datos={ datos: {
                            nit: this.getField('nit').getValue(),
                            nombre: this.getField('nombre').getValue(),
                            telefonos: this.getField('telefonos').getValue(),
                            email: this.getField('email').getValue(),
                            ciudad_id: this.getField('ciudad_id').getValue(),
                            departamento_geografico_id: this.getField('departamento_geografico_id').getValue(),
                            paises_id: this.getField('paises_id').getValue(),
                            direccion: this.getField('direccion').getValue(),
                            tipo_documento_id: this.getField('tipo_documento_id').getValue(),
                            verifica: this.getField('verifica').getValue(),
                            codigo_vendedor: this.getField('codigo_vendedor').getValue(),
                            nom1: this.getField('nom1').getValue(),
                            nom2: this.getField('nom2').getValue(),
                            ape1: this.getField('ape1').getValue(),
                            ape2: this.getField('ape2').getValue(),
                            fecha_nacimiento: this.getField('fecha_nacimiento').getValue(),
                            sexo: this.getField('sexo').getValue(),
                            tipo_persona: this.getField('tipo_persona').getValue(),
                            autoretenedor: this.getField('tipo_persona').getValue() === 'J' ? 'S' : 'N',
                            cliente_remisiona: 'N',
                        }};
                        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'crear', operacion_tipo: 'crear' };
                        this.service.send({
                            endpoint: this.constant.formConfiguration(),
                            method:'POST',
                            body: datos,
                            success: this.successGuardarCliente,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            //showMessage: false
                        });

                    } else {
                        this.getField('confirmModalCustom').setTitleAndContent('Error', `Mínimo debe ir un nombre con un apellido`)
                        this.getField('confirmModalCustom').setClickDialog(() => {
                            this.getField('confirmModalCustom').toggle(false);
                            this.getField('ape1').setError(true, '* Este campo es requerido.');
                            this.getField('ape2').setError(true, '* Este campo es requerido.');
                        });
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                    }
                }
            }
        }
    }

    successGuardarCliente(data) {
        if (data.estado_p === 200) {
            //this.pedidosPendientes(); // después de agregar un cliente se refresca la tabla principal
            this.registroSeleccionado = ''; // Esta línea se coloca acá para al crear un nuevo cliente, se refresque la tabala sin ningun registro seleccionado.
            this.arrayNuevo = [];
            this.nuevoPedidosPendientes();
            if (data.data[0].nitsx_sedes_id !== null && data.data[0].nitsx_sedes_id !== undefined)
                this.id_cliente = data.data[0].nitsx_sedes_id;
            else
                this.id_cliente = data.data[0].nid;
            this.precio_cliente = data.data[0].precios;
            this.getField('precios_cliente').setValue(this.precio_cliente);
            this.getField('tercero_id_sede1').setValue(this.id_cliente);
    
            
            let datos={ datos: {
                codigo_sucursal:this.codigo_sucursal
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'traerconsecutivo', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerConsecutivo,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage: false
            });
            this.getField('modalClientes').handleClose();
        }
    }

    traerDepartamentos() {
        this.getField('paises_id').getValue() !== '' ? this.getField('paises_id').setError(false, '') : this.getField('paises_id').valid();
        if (this.getField('paises_id').getValue() !== '' && this.getField('paises_id').getValue() !== ' ') {
            
            let datos={ datos: {
                pais_id: parseInt(this.getField('paises_id').getValue()),
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-deptosgeograficos', operacion: 'paisid', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDepartamentos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        } else {
            this.getField('departamento_geografico_id').setOptions(this.opciones);
            this.getField('ciudad_id').setOptions(this.opciones);
        }
    }

    successTraerDepartamentos(data) {
        if (data.estado_p === 200 && data.data.length >= 1) {
            let arrayFiltrar = [];
            let itemIncrement = 0;
            data.data.map((itemFiltrar) => {
                if (itemFiltrar.estadoactivo_id === 1) {
                    arrayFiltrar.push(data.data[itemIncrement]);
                }
                itemIncrement += 1;
                return null;
            });
            let opciones = [{ text: 'Ninguno', value: '', 'campos_cambian': {} }];
            arrayFiltrar.forEach(item => {
                let dataOp = {}
                dataOp['value'] = `${item.id}`;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {};
                opciones.push(dataOp);
            })
            this.getField('departamento_geografico_id').setOptions(opciones);
            this.getField('departamento_geografico_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un pais que se habia seleccionado con anterioridad, no se setee el mismo departamento, sino que solo liste el select con los departamentos correspondientes al país seleccionado.
            this.getField('departamento_geografico_id').setError(false, '');
        } else {
            this.getField('departamento_geografico_id').setOptions(this.opciones);
        }
    }

    traerCiudades() {
        this.getField('departamento_geografico_id').getValue() !== '' ? this.getField('departamento_geografico_id').setError(false, '') : this.getField('departamento_geografico_id').valid();
        if (this.getField('departamento_geografico_id').getValue() !== '' && this.getField('departamento_geografico_id').getValue() !== ' ') {

            let datos={ datos: {
                departamento_geografico_id_ciudades1: parseInt(this.getField('departamento_geografico_id').getValue()),
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-ciudades', operacion: 'deptoid', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerCiudades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        } else {
            this.getField('ciudad_id').setOptions(this.opciones);
        }
    }

    successTraerCiudades(data) {
        if (data.estado_p === 200 && data.data.length >= 1) {
            let arrayFiltrar = [];
            let itemIncrement = 0;
            data.data.map((itemFiltrar) => {
                if (itemFiltrar.estadoactivo_id === 1) {
                    arrayFiltrar.push(data.data[itemIncrement]);
                }
                itemIncrement += 1;
                return null;
            });
            let opciones = [{ text: 'Ninguno', value: '', 'campos_cambian': {} }];
            arrayFiltrar.forEach(item => {
                let dataOp = {}
                dataOp['value'] = `${item.id}`;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {};
                opciones.push(dataOp);
            })
            this.getField('ciudad_id').setOptions(opciones);
            this.getField('ciudad_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un departamento que se habia seleccionado con anterioridad, no se setee la misma ciudad, sino que solo liste el select con las ciudades correspondientes al departmento seleccionado.
            this.getField('ciudad_id').setError(false, '');
        } else {
            this.getField('ciudad_id').setOptions(this.opciones);
        }
    }

    agregarCliente() {
        let fechaModificada = `2000-01-01`;
        let cambioFormato = Date.parse(fechaModificada);
        let fecha = new Date(cambioFormato);
        let campoFecha = fecha.toISOString();
        this.getField('fecha_nacimiento').setValue(campoFecha.split('T')[0]);
        this.getField('nom1').setValue('.');
        this.getField('nom2').setValue('.');
        this.getField('ape1').setValue('.');
        this.getField('ape2').setValue('.');
        this.getField('sexo').setValue('M');

        this.limpiarMensajesErrorModalAgregarCliente();
        this.getField('modalClientes').handleClickOpen(this.initModal);
    }

    limpiarMensajesErrorModalAgregarCliente() {
        this.getField('nit').setValue('');
        this.getField('nit').setError(false, "");
        this.getField('verifica').setError(false, "");
        this.getField('tipo_documento_id').setError(false, "");
        this.getField('nombre').setError(false, "");
        this.getField('paises_id').setError(false, "");
        this.getField('departamento_geografico_id').setError(false, "");
        this.getField('ciudad_id').setError(false, "");
        this.getField('direccion').setError(false, "");
        this.getField('telefonos').setError(false, "");
        this.getField('email').setError(false, "");
        this.getField('codigo_vendedor').setError(false, "");
        this.getField('nombre_vendedor').setError(false, "");
        this.getField('nom1').setError(false, "");
        this.getField('nom2').setError(false, "");
        this.getField('ape1').setError(false, "");
        this.getField('ape2').setError(false, "");
        this.getField('fecha_nacimiento').setError(false, "");
        this.getField('sexo').setError(false, "");
        this.getField('tipo_persona').setError(false, "");
    }

    buscarClientePorNit() {
        let terceroIdSede =  this.getField('tercero_id_sede').getValue();
        if(terceroIdSede !== '' && terceroIdSede !== ' ' && terceroIdSede !== undefined){
            if (this.getField('tercero_id_sede').getValue() !== '' && this.getField('tercero_id_sede').getValue() !== '.' && this.getField('tercero_id_sede').getValue() >= 1) {

                this.arrayPendientes = [];
                this.arrayNuevo = [];
                
                let datos={ datos: {
                    nit: this.getField("cliente").getValue(),
                    codigo_sucursal: this.codigo_sucursal
                }};
                this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'traerencabezadopedidosnit', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successNuevoPendientes,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }

        }
    }

    limpiarCampos() {
        if (this.getField('nit_buscar').getValue() === '' && this.getField('tercero_id_sede').getValue() === '') {

            this.getField('tablaClientes').toggle(false);
            this.getField('tablaProductos').toggle(false);
            this.getField('tabla_terceros').toggle(false);
            if(this.getField('cliente').getValue() === ''){
                this.getField("cliente").setKeyUp(() => {});
                this.arrayNuevo = [];
                this.nuevoPedidosPendientes();
            }else{
                this.getField("cliente").setKeyUp((event) => this.getField("cliente").changeKeyUp(event));
            }
        }
    }

    //este metodo se implemento para traer la, ó las bodegas para la sucursal de ingreso, las cuales se almacenan en una variable global para ser utilizada mas adelante
    traerCodigoBodegaSaldo() {
        let datos={ datos: {
            codigo_sucursal: this.getField('sucursal_ingreso').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'saldobodegasproductos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.succesTraerCodBodegaSaldo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    succesTraerCodBodegaSaldo(data) {
        if (data.estado_p === 200) {
            let saldo_bodegas = data.data[0].saldo_bodegas_productos;//'15,14,21'
            let arrayBodegas = [];

            if (saldo_bodegas.includes(',')) { // cuando se trae mas de una bodega separadas por coma , se toma por defecto la primer bodega
                arrayBodegas = saldo_bodegas.split(',');
                this.saldo_bodegas_productos = arrayBodegas[0];

            } else {

                this.saldo_bodegas_productos = data.data[0].saldo_bodegas_productos; // cuando se trae solamente una bodega
            }
        }
    }
    
    setButtonDeleteProducto(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.eliminarProducto(id);
        button.setAttribute("id", 'button_Add_' + id);
        div.setAttribute('id', 'div_Add_' + id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    eliminarProducto(id) {
        this.id_delete = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDeleteCustom);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setButtonCancel("Cancelar")
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDeleteCustom() {
        this.getField('confirmModalCustom').toggle(false);
        let datos={ datos: {
            id: this.id_delete,
            estado: "P"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'eliminar', operacion_tipo: 'eliminar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'DELETE',
            body: datos,
            success: this.successDelete,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
        
    }

    successDelete(data) {
        if (data.estado_p === undefined || data.estado_p === 200) {
            this.totalCantidadProductos = 0;
            if(this.getField("nombre_filtrar").getValue() === ""){
                this.uploadTable();
            }else{
                this.getField('tablaProductos').toggle(false);
                this.getField("nombre_filtrar").setDisabled(true);
                this.getField("btnFiltrar").setDisabled(true);
                
                let datos={ datos: {
                    cliente_id: this.cliente_id,
                    bodega: this.saldo_bodegas_productos,
                    numero_pedido: this.codigoConsecutivo,
                    estado:"P",
                    nombre:this.getField("nombre_filtrar").getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporclientefiltrar', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successUploadTable,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }

        }
    }

    uploadTable() {
        this.copiaData = [];

        this.totalCantidadProductos = 0;        
        let datos={ datos: {
            cliente_id: this.id_cliente,
            bodega: this.saldo_bodegas_productos,
            numero_pedido: this.codigoConsecutivo,
            codigo_sucursal: this.codigo_sucursal,
            estado:"P"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporcliente', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successUploadTable,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
        this.getField('tercero_id_sede').setValue(this.id_cliente);
    }

    successUploadTable(data) {
        this.getField("nombre_filtrar").setError(false,"");
        if (data.estado_p === 200) {
            this.getField("nombre_filtrar").setDisabled(false);
            this.getField("btnFiltrar").setDisabled(false);

            if (data.ultimo) {
                if (data.data[0].cuantos > 0)
                    this.agregarDescuento = true;
                else
                    this.agregarDescuento = false;
                this.recuperarCodigosArray(data.ultimo)
            }
            else {
                this.agregarDescuento = false;
                this.recuperarCodigosArray(data.model)
            }

        } else {
            if(this.getField("nombre_filtrar").getValue() === ""){
                this.getField("nombre_filtrar").setDisabled(true);
                this.getField("btnFiltrar").setDisabled(true);
            }else{
                this.getField("nombre_filtrar").setDisabled(false);
                this.getField("btnFiltrar").setDisabled(false);
            }
            this.getField('tablaProductos').toggle(false);
            this.getField('total_producto').setValue(0);
        }
        this.case = null;

    }

    descuentoChange(props) {
        let seguir = this.getField('tablaProductos').cellValid(props.oldValue, props.newValue, /^(\d{1,3})(\.\d{1,4})?$/);
        if (seguir) {
            let valor_actual = props.newValue;
            if(valor_actual < 100){

                let datos={ datos: {
                    id: props.data.id,
                    descuento: parseFloat(valor_actual)
                }};
                this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'modificardescuento', operacion_tipo: 'modificar' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successGuardarDescuento,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }else{
                this.alertGeneral.toggle(true, 'No se permite un descuento del 100%', 'error');
            }
        } else if (seguir !== null)
            this.initModalProductos();
    }
    
    successGuardarDescuento(data) {
        if(this.getField("nombre_filtrar").getValue() === ""){
            this.uploadTable();
        }else{
            this.getField('tablaProductos').toggle(false);
            this.getField("nombre_filtrar").setDisabled(true);
            this.getField("btnFiltrar").setDisabled(true);
            
            let datos={ datos: {
                cliente_id: this.cliente_id,
                bodega: this.saldo_bodegas_productos,
                numero_pedido: this.codigoConsecutivo,
                estado:"P",
                nombre:this.getField("nombre_filtrar").getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporclientefiltrar', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successUploadTable,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    buscarProductos() {
        //this.getField('tercero_id_sede').setValue('');
        this.getField('nit_buscar').setValue('');
        this.getField('producto_inicial').setError(false,'');
        this.getField('modalTodasBodegas').handleClickOpen(this.initModalBodegas);
    }

    initModalBodegas() {
        let datos = {datos:{
            codigo_sucursal:this.codigo_sucursal
        }}
        this.generalFormatPmv = { tipo_servicio: 'most-pedidos', operacion: 'codigofacturacion', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.codigoFacturacion,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
    }

    codigoFacturacion(data) {
        if (data.estado_p === 200) {
            this.getField('codigo_facturacion').setValue(data.data[0].codigo_facturacion);
        } else {
            this.getField('codigo_facturacion').setValue('');
        }
    }

    guardarProducto() {
        this.getField("nombre_marca").setValue('.');
        this.getField('nit_buscar').setValue(0);
        this.getField("observacion").setValue(".");
            
        let datos={ datos: {
            id: this.cliente_id,
            numero_pedido: this.codigoConsecutivo,
            codigo_sucursal:this.codigo_sucursal,
            estado:"P"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'buscarid', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDatosClientes,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successDatosClientes(data) {

        let datosProducto = [];
        if (data.estado_p === 200) {
            datosProducto.push(data.data[0]);
            
            let datos={ datos: { }};
            this.generalFormatPmv = { tipo_servicio: 'most-usuarios', operacion: 'permisodescuento', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successModificarDescuento,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
        this.mensajeErrorProducto();
        this.getField('modalAgregarProducto').handleClickOpen(this.addProductoCliente, datosProducto);
        this.getField('nombre_marca').setKeyUp(this.habilitarBusquedaNombreMarca);
        this.getField('producto').setOnBlur(this.habilitarBusquedaNombreMarca);
        this.getField('producto').setDisabled(true);
        this.getField('cantidad').setValue(0);
        this.getField('descuento').setValue(0);
        this.getField('descuento').setError(false,'');
        this.getField('marca').setValue(0);
        this.getField('precio').setValue(0);
        this.getField('precio_incluye_iva').setOnChange(this.setPrecioIncluyeIva);

    }

    successModificarDescuento(data) {
        if (data.estado_p === 200) {
            if (this.case === 4) {
                if (data.data[0].cuantos > 0) {
                    this.getField('descuento').setDisabled(false);
                } else {
                    this.getField('descuento').setDisabled(false);
                    this.getField('descuento').setValue(0);
                }
                this.case = null;
            } else {
                if (data.model[0].modificar_descuento) {
                    this.getField('descuento').setDisabled(false);
                } else {
                    this.getField('descuento').setDisabled(false);
                    this.getField('descuento').setValue(0);
                }
            }
        } else
            this.getField('modalAgregarProducto').handleClose();
    }
    
    mensajeErrorProducto() {
        this.getField('total_items').setError(false, "");
        this.getField('total_general_cliente').setError(false, "");
        this.getField('producto').setError(false, "");
        this.getField('nombre_marca').setError(false, "");
        this.getField('marca').setError(false, "");
        this.getField('tarifa_iva').setError(false, "");
        this.getField('nombre_producto').setError(false, "");
        this.getField('precio').setError(false, "");
        this.getField('presenta').setError(false, "");
        this.getField('precio_mas_iva').setError(false, "");
        this.getField('referencia').setError(false, "");
        this.getField('existencia').setError(false, "");
        this.getField('precios_cliente').setError(false, "");
        this.getField('cantidad').setError(false, "");
        this.getField('descuento').setError(false, "");
        this.getField('total_producto_agregar').setError(false, "");

        this.getField('producto').setValue('');
        this.getField('marca').setValue('');
        this.getField('tarifa_iva').setValue('');
        this.getField('nombre_producto').setValue('');
        this.getField('precio').setValue('');
        this.getField('presenta').setValue('');
        this.getField('precio_mas_iva').setValue('');
        this.getField('referencia').setValue('');
        this.getField('existencia').setValue('');
        this.getField('cantidad').setValue('');
        this.getField('descuento').setValue('');
        this.getField('total_producto_agregar').setValue('');
    }

    addProductoCliente(datosProducto) {

        this.getField('precio_mas_iva').setKeyUp(this.calcularPrecioProducto);
        this.getField('nit_buscar').setValue(0);
        this.getField('precio').setKeyUp(this.totalProducto2);
        this.getField('referencia').setOnChange(this.totalProducto2);
        this.getField('cantidad').setKeyUp(this.totalProducto);
        this.getField('descuento').setKeyUp(this.totalProducto);
        this.getField('nitsx_sedes_id').setValue(this.id_cliente);
        this.getField('precios_cliente').setValue(this.precio_cliente);
        this.getField('nombre_producto').setOnChange(this.formatearPrecios);
        this.maneja_remision === true ? this.getField('observacion').setOnBlur(this.modalConfirmDescuento) : this.getField('descuento').setOnBlur(this.modalConfirmDescuento);
        this.validarDescuentoMax(this.cliente_id);
    
        if (datosProducto !== '' && datosProducto.length > 0) { //
            this.getField('total_items').setValue(datosProducto[0].total_items);
            this.getField('total_general_cliente').setValue(datosProducto[0].total_general.toFixed(2));
        }
        this.getField('btnAgregarDatosCliente').setClick(this.addProdutoACliente);
        this.getField('listaAgregarProducto').setItemsFichas(datosProducto);
    
    }

    calcularPrecioProducto() {
        let precio_Iva = this.getField('precio_mas_iva').getValue();
        let tarifa_Iva = this.getField('tarifa_iva').getValue();
        let precio_sin_Iva = precio_Iva / (1 + (tarifa_Iva) / 100);
        this.getField('precio').setValue(precio_sin_Iva.toFixed(2));
        if (parseFloat(this.getField('precio').getValue().toFixed(2)) < parseFloat(this.precio_base)) {
            this.getField('precio').setError(true, `El precio debe ser mayor al precio base: $ ${'' + this.currencyFormatterGeneral(this.precio_base)}`);
        } else {
            this.getField('precio').setError(false, '');
        }
        this.totalProducto();
    }

    totalProducto2() {
        this.getField('descuento').setValue(0);
        if (this.getField('precio').getValue() !== '' && this.getField('precio').getValue() !== ' ') {
            if (parseFloat(this.getField('precio').getValue().toFixed(2)) < parseFloat(this.precio_base)) {
                this.getField('precio').setError(true, `El precio debe ser mayor al precio base: $ ${'' + this.currencyFormatterGeneral(this.precio_base)}`);
            } else {
                this.getField('precio').setError(false, '');
                if (parseFloat(this.getField('precio').getValue().toFixed(2)) !== parseFloat(this.precio_base)) {// Esta validación se hace para saber sí el campo "precio" se ha modificado o no; si no se modificó, no debe llamar a la función "precioTotalIvaCantidadDecimal" de la dashboard, pero si lo modificó, se debe calcular el pecio mas iva con dicha función.
                    if (this.getField('precio_incluye_iva').getValue() === 'S') {//Código cuando la variable del properties "precio_incluye_iva" es igual a "S":
                        this.getField('precio_mas_iva').setValue(this.precioTotalIvaCantidadDecimalConIvaS(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), 0, 1));
                    }else if (this.getField('precio_incluye_iva').getValue() === 'N') {//Código cuando la variable del properties "precio_incluye_iva" es igual a "N":
                        this.getField('precio_mas_iva').setValue(this.precioTotalIvaCantidadDecimalConIvaN(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), 0, 1));
                    }
                }else{
                    if (this.getField('precio_incluye_iva').getValue() === 'S') {//Código cuando la variable del properties "precio_incluye_iva" es igual a "S":
                        this.getField('precio_mas_iva').setValue(this.precioTotalIvaCantidadDecimalConIvaS(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), 0, 1));
                    }else if (this.getField('precio_incluye_iva').getValue() === 'N') {//Código cuando la variable del properties "precio_incluye_iva" es igual a "N":
                        this.getField('precio_mas_iva').setValue(this.precioTotalIvaCantidadDecimalConIvaN(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), 0, 1));
                    }
                }
                if (this.getField('cantidad').getValue() !== '' && this.getField('descuento').getValue() !== '' && this.getField('precio').getValue() !== '') {
                    if (this.getField('precio_incluye_iva').getValue() === 'S') {
                        let precio_total = this.precioTotalIvaCantidadDecimalConIvaS(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), this.getField('descuento').getValue(), this.getField('cantidad').getValue());
                        this.getField('total_producto_agregar').setValue(precio_total.toFixed(2));
                    }else if (this.getField('precio_incluye_iva').getValue() === 'N') {
                        let precio_total = this.precioTotalIvaCantidadDecimalConIvaN(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), this.getField('descuento').getValue(), this.getField('cantidad').getValue());
                        this.getField('total_producto_agregar').setValue(precio_total.toFixed(2));
                    }
                } else if (this.getField('cantidad').getValue() !== '') {
                    if (this.getField('precio_incluye_iva').getValue() === 'S') {
                        let precio_total = this.precioTotalIvaCantidadDecimalConIvaS(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), 0, this.getField('cantidad').getValue());
                        this.getField('total_producto_agregar').setValue(precio_total.toFixed(2));
                    }else if (this.getField('precio_incluye_iva').getValue() === 'N') {
                        let precio_total = this.precioTotalIvaCantidadDecimalConIvaN(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), 0, this.getField('cantidad').getValue());
                        this.getField('total_producto_agregar').setValue(precio_total.toFixed(2));
                    }
                }
                if (parseFloat(this.getField('cantidad').getValue()) > parseFloat(this.getField('existencia').getValue())) {
                    this.seguir = false;
                    this.getField('cantidad').setError(true, '* Este valor no puede ser mayor a las existencias');
                } else {
                    this.seguir = true;
                    this.getField('cantidad').setError(false, '');
                    this.getField('cantidad').valid();
                    this.getField('descuento').valid();
                }
            }
        }
        this.getField('cantidad').setError(false, '');
    }

    totalProducto() {
        if (this.getField('precio_incluye_iva').getValue() === 'S') {// La diferencia en que "precio_incluye_iva" sea S o N, es que para calcular el "total_producto_agregar" a la funcio del dashboard "precioTotalIvaCantidadDecimal" cuando es S, se le pasa 3 parametros (precio_mas_iva,descuento,cantidad) y cuando es N, se le pasa 4 parametros (precio,tarifa_iva,descuento,cantidad)
            this.calcularTotalProductoAgregarConIvaS();
        } else if (this.getField('precio_incluye_iva').getValue() === 'N') {
            this.calcularTotalProductoAgregarConIvaN();
        }
    }

    formatearPrecios() {
        if (this.getField('precio').getValue() !== '') {// Se hace esta condición if, ya que se genera un error con el toFixed, porque el campo "precio" está vacío, entonces se valida primero que dicho campo esté lleno para poder hacer el toFixed.
            this.precio_base = this.getField('precio').getValue().toFixed(2);// Se coloca el "toFixed(2)" porque fue uno de los requerimientos y porque por BD solo acepta 2 decimales, entonces todos los cálculos y validaciones se hará teniendo en cuenta estos parámetros.
        }
        this.getField('descuento').setValue(0);
        if (this.getField('precio').getValue() !== '')
            this.getField('precio').setValue(this.getField('precio').getValue());

        if (parseInt(this.getField('precio').getValue()) === 0) {
            this.getField('cantidad').setValue(0);
            this.getField('descuento').setValue(0);
            this.getField('total_producto_agregar').setValue(0);
        }

        if (this.getField('existencia').getValue() !== '')
            this.getField('existencia').setValue(this.getField('existencia').getValue());

        if (this.getField('precio_mas_iva').getValue() !== '')
            this.getField('precio_mas_iva').setValue(this.getField('precio_mas_iva').getValue());


    }

    modalConfirmDescuento() { // se hace el cambio para que con el set on blur del campo descuento, se consuma el servicio de validar descuento antes de mostrar los confirm dialogs al usuario
        this.addProdutoACliente();
    }

    addProdutoACliente() { // en esta funcion se cambio para que se consuma el servicio de validar descuento y posteriormente con la respuesta de dicho servicio responder al usuario un determinado mensaje 
        if(!this.maneja_remision){
            this.getField("observacion").setValue(".");
        }
        if((this.getField('producto').valid() || this.getField('producto_remision').valid() ) && this.getField('cantidad').valid() && this.getField("observacion").valid()){
            let datos={ datos: { 
                nit: this.getField('nit').getValue(),
                sede: this.getField('sede').getValue(),
                descuento: this.getField('descuento').getValue(),
                codigo: this.maneja_remision? this.getField('producto_remision').getValue() :this.getField('producto').getValue(),
                precios: this.getField('precios').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'validardescuentomaximo', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successValidarDescuento,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false//(Código viejo: this.getField('descuento').getValue() >= 1 ? true : false) //Se comenta esta línea ya que en la tarea "JBAS-2962", solicitaron no mostrar el valor del descuento máximo permitido e informar al usuario simplemente que el descuento no es permitido.
            });
        }
    }

    successValidarDescuento(data) { // en esta funcion queda el cambio donde se muestra un dialog diferente dependiendo de la respuesta del servicio
        if(data.data.ver_descuento_maximo === 'S'){ // esta variable (ver_descuento_maximo) viene de un properties
            if (data.estado_p === 200) { // si estado_p === 200, se debe mostrar el confirm dialog para agregar el producto
                this.alertGeneral.toggle(true,data.data.mensaje,"success");
                this.getField('descuento').setError(false, ".");

                if (this.getField('cantidad').getValue() >= 0.01) {
                    this.getField('confirmModalCustom').setTitleAndContent('Agregar Item', '¿Desea agregar este ítem?');
                    this.getField('confirmModalCustom').setClickDialog(this.confirmAceptarCustom);
                    this.getField('confirmModalCustom').setButtonConfirm(`Confirmar`);
                    this.getField("confirmModalCustom").setButtonCancel("Cancelar")
                    this.getField("confirmModalCustom").setVisibleCancel(false);
                    this.getField('confirmModalCustom').toggle(true);
                } else {
                    this.getField('confirmModalCustom').toggle(false);
                }

            } else { // sino se debe mostrar el dialog de descuento no permitido
                this.getField('descuento').setError(true, "* Descuento no autorizado.");
                this.alertGeneral.toggle(true,data.data.mensaje,"error");
            } 

        } else {// si la variable del properties viene en 'N' no se muestra ningun mensaje de alerta al usuario y pasa a agregar producto directamente
            this.service.alertError.toggle(false);// esta linea oculta el mensaje de alerta cuando el servicio responde justo antes de que se muestre

            if (this.getField('cantidad').getValue() >= 0.01) {
                this.getField('confirmModalCustom').setTitleAndContent('Agregar Item', '¿Desea agregar este ítem?');
                this.getField('confirmModalCustom').setClickDialog(this.confirmAceptarCustom);
                this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); 
                this.getField("confirmModalCustom").setButtonCancel("Cancelar")
                this.getField("confirmModalCustom").setVisibleCancel(false);// para que se muestre el boton de cancelar
                this.getField('confirmModalCustom').toggle(true);
            } else {
                this.getField('confirmModalCustom').toggle(false);
            }

        }
    }

    validarDescuentoMax(idBuscar) {
        if (idBuscar >= 1) {
            let datos={ datos: { 
                id: idBuscar,
                codigo_sucursal:this.codigo_sucursal
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-terceros', operacion: 'buscarid', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successValidarDescuentoMax,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successValidarDescuentoMax(data) {
        if (data.estado_p === 200) {
            this.getField('precios_cliente').setValue(data.data[0].precios);
            this.getField('nit').setValue(data.data[0].nit);
            this.getField('sede').setValue(data.data[0].sede);
            this.getField('precios').setValue(data.data[0].precios);
            this.getField('codigo').setValue(this.maneja_remision?this.getField('producto_remision').getValue(): this.getField('producto').getValue());
            this.precio_cliente = data.data[0].precios;
            this.getField('precios_cliente').setValue(data.data[0].precios);
            if (this.getField('precios_cliente').getValue() !== undefined && this.getField('precios_cliente').getValue() !== '') {
                this.getField('producto').setDisabled(false);
                this.getField('producto').setError(false, 'No hay una lista de precios configurada');
                this.getField('precios_cliente').setError(false, 'No hay una lista de precios configurada');
            } else {
                this.getField('producto').setError(true, 'No hay una lista de precios configurada');
                this.getField('precios_cliente').setError(true, 'No hay una lista de precios configurada');
            }
        } else {
            this.alertGeneral.toggle(true, 'Error validando descuento', "error");
        }
    }

    habilitarBusquedaNombreMarca() {
        this.getField('precio').setError(false, "");
        this.getField('descuento').setError(false, "");

        if (this.getField('nombre_marca').getValue() !== "." && this.getField('nombre_marca').getValue() !== ''
            && (this.getField('producto').getValue() !== '' || this.getField('producto_remision').getValue() !== '')) {
            this.getField('bt_buscar_por_nombre_marca').setDisabled(false);

        } else if (this.getField('producto').getValue() === '' && this.getField('producto_remision').getValue() === '') {
            this.getField('bt_buscar_por_nombre_marca').setDisabled(true);
            this.getField('producto').setError(true, "El nombre del producto es requerido");
        } else if (this.getField('nombre_marca').getValue() === ".") {
            this.getField('bt_buscar_por_nombre_marca').setDisabled(true);
        }
    }

    setPrecioIncluyeIva() {
        this.getField('precio_incluye_iva').getVisible(true);
        if (this.getField('precio_incluye_iva').getValue() !== undefined && this.getField('precio_incluye_iva').getValue() !== '' && this.getField('precio_incluye_iva').getValue() !== ' ') {
            console.log(this.getField('precio_incluye_iva').getValue());
            if (this.getField('precio_incluye_iva').getValue() === 'S') {
                this.getField('precio_mas_iva').setDisabled(false);
                this.getField('precio').setDisabled(true);
                this.getField('precio').setValue(this.precio_base);
                //this.calcularPrecioProducto(); // Esta función se llama acá, ya que se solició que una vez se consulte el producto haga el cálculo del precio
            } else if (this.getField('precio_incluye_iva').getValue() === 'N') {
                this.getField('precio_mas_iva').setDisabled(true);
                this.getField('precio').setDisabled(false);
                this.getField('precio').setValue(this.precio_base);
                this.getField('precio_mas_iva').setValue(this.precioTotalIvaCantidadDecimalConIvaN(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), 0, 1));// Una vez se setea el campo "precio" toca recalcular el campo "precio mas iva", es por esta razón que se llama esta función del dashboard (precioTotalIvaCantidadDecimal) cómo se hace en la función "totalProducto2".
            }
        }
    }

    /**
     * @param {Este parámetro vendría tomando el valor que retorna el parámetro "precio" del servicio con ruta "mostradorproductos-codigotodos" y nombre acción "servicio_buscar_producto_por_coincidencia_codigo"} precio 
     * @param {Este parámetro vendría tomando el valor que retorna el parámetro "iva" del servicio con ruta "mostradorproductos-codigotodos" y nombre acción "servicio_buscar_producto_por_coincidencia_codigo"} iva 
     * @param {Este parámetro por defecto viaja con un 0} descuento 
     * @param {Este parámetro por defecto viaja con un 1} cantidad 
     * @returns Es el precio_total segun el cálculo realizado en dicha función
     */
    precioTotalIvaCantidadDecimalConIvaN(precio,iva,descuento,cantidad){
        let Precio_sin_iva_con_descuento = "".redondear(parseFloat(precio) * (1 - (parseFloat(descuento)/100)),2);
        let Precio_total1 = "".redondear(Precio_sin_iva_con_descuento * parseFloat(cantidad), 2);
        let valor_iva = Math.round(Precio_total1 * (parseFloat(iva)/100));
        valor_iva = "".redondear(valor_iva / parseFloat(cantidad),4);
        let total_iva = "".redondear(valor_iva * parseFloat(cantidad),2);
        let precio_total = "".redondear(Precio_total1 + total_iva,2);
        return Number(precio_total);
    }

    confirmCloseAlertModalDescuento(){
        this.getField("confirmModalCustom").toggle(false);
    }

    calcularTotalProductoAgregarConIvaN(){
        if (this.getField('precio').getValue() !== '' && this.getField('precio').getValue() !== ' ') {
            if (parseFloat(this.getField('precio').getValue()) < parseFloat(this.precio_base)) {
                this.getField('precio').setError(true, `El precio debe ser mayor al precio base: $ ${'' + this.currencyFormatterGeneral(this.precio_base)}`);
            } else {
                this.getField('precio').setError(false, '');
                if (this.getField('cantidad').getValue() !== '' && this.getField('descuento').getValue() !== '' && this.getField('precio').getValue() !== '') {
                    let precio_total = this.precioTotalIvaCantidadDecimalConIvaN(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), this.getField('descuento').getValue(), this.getField('cantidad').getValue());
                    this.getField('total_producto_agregar').setValue(precio_total.toFixed(2));
                } else {
                    let precio_total = this.precioTotalIvaCantidadDecimalConIvaN(this.getField('precio').getValue(), this.getField('tarifa_iva').getValue(), 0, this.getField('cantidad').getValue());
                    this.getField('total_producto_agregar').setValue(precio_total.toFixed(2));
                }
                if (parseFloat(this.getField('cantidad').getValue()) > parseFloat(this.getField('existencia').getValue())) {
                    this.seguir = false;
                    this.getField('cantidad').setError(true, '* Este valor no puede ser mayor a las existencias');
                } else {
                    this.seguir = true;
                    this.getField('cantidad').setError(false, '');
                    this.getField('cantidad').valid();
                    this.getField('descuento').valid();
                }
            }
        }
    }

    calcularTotalProductoAgregarConIvaS(){
        if (this.getField('precio_mas_iva').getValue() !== '' && this.getField('precio_mas_iva').getValue() !== ' ') {
            if (this.getField('cantidad').getValue() !== '' && this.getField('descuento').getValue() !== '' && this.getField('precio_mas_iva').getValue() !== '') {
                let precio_total = this.precioTotalIvaCantidadDecimalConIvaS(this.getField('precio_mas_iva').getValue(), 0, this.getField('descuento').getValue(), this.getField('cantidad').getValue());
                this.getField('total_producto_agregar').setValue(parseFloat(precio_total).toFixed(2));
                this.getField('total_producto_agregar').setError(false, "");
            } else {
                let precio_total = this.precioTotalIvaCantidadDecimalConIvaS(this.getField('precio').getValue(), 0, 0, this.getField('cantidad').getValue());
                this.getField('total_producto_agregar').setValue(parseFloat(precio_total).toFixed(2));
                this.getField('total_producto_agregar').setError(false, "");
            }
            if (parseFloat(this.getField('cantidad').getValue()) > parseFloat(this.getField('existencia').getValue())) {
                this.seguir = false;
                this.getField('cantidad').setError(true, '* Este valor no puede ser mayor a las existencias');
            } else {
                this.seguir = true;
                this.getField('cantidad').setError(false, '');
                //this.getField('cantidad').valid();
                this.getField('descuento').valid();
            }
        }
    }

    precioTotalIvaCantidadDecimalConIvaS(precio,iva,descuento,cantidad){
        let Precio_sin_iva_con_descuento = precio * (1 - (parseFloat(descuento)/100));
        let Precio_total1 = Precio_sin_iva_con_descuento * parseFloat(cantidad);
        let valor_iva = Precio_total1 * (parseFloat(iva)/100);
        valor_iva = valor_iva / parseFloat(cantidad);
        let total_iva = valor_iva * parseFloat(cantidad);
        let precio_total = (Precio_total1 + total_iva).toFixed(2);
        return Number(precio_total);
    }
    
    confirmAceptarCustom() {
        this.getField('confirmModalCustom').toggle(false);
        
        if (this.seguir) {
            if (
                this.getField('cantidad').valid()
                & this.getField('nombre_producto').valid()
                & (this.getField('producto').valid() || this.getField('producto_remision').valid())
                & this.getField('precio').valid()
                & this.getField('descuento').valid()
                & this.getField('total_producto_agregar').valid()
            ) {
                let codigo = this.maneja_remision?this.getField('producto_remision').getValue(): this.getField('producto').getValue();
                let estadoAgregarItem = false;
                this.copiaData.forEach((item) => {
                    if (codigo === item.codigo) {
                        estadoAgregarItem = true;
                        this.alertGeneral.toggle(true, '* El item ya fue agregado','error');
                        this.mensajeErrorProducto();
                    }
                });

                if (!estadoAgregarItem) {

                    if (this.getField('precio').getValue() >= this.precio_base && this.getField('sucursal_ingreso').getValue() !== '') {//this.getField('precio').getValue() < precio_base

                        let datos = { datos: {
                                sucursal_ingreso: this.getField('sucursal_ingreso').getValue(),
                                nitsx_sedes_id: this.getField('nitsx_sedes_id').getValue(),
                                articulo_id: this.getField('articulo_id').getValue(),
                                cantidad: this.getField('cantidad').getValue(),//replace(/,/g,'')
                                descuento: this.getField('descuento').getValue(),
                                precio: this.getField('precio').getValue(),
                                precios: this.precio_cliente,
                                numero_pedido: this.codigoConsecutivo,
                                tipo_pedido: this.tipoPedido,
                                tipo_despacho: this.tipoDespacho,
                                remision: this.maneja_remision === true? this.getField("remision_producto").getValue():"N",
                                observacion: this.getField("observacion").getValue()

                            }
                        }; 

                        this.getField('btnAgregarDatosCliente').setDisabled(true); ///
                        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'crear', operacion_tipo: 'crear' };
                        this.service.send(
                            {
                                endpoint: this.constant.formConfiguration(),
                                method: 'POST',
                                body: datos,
                                success: this.successGuardarProducto,
                                error: this.error_,
                                general: this.generalFormatPmv
                            });
                    } else {
                        this.getField('precio').setError(true, `El precio debe ser mayor al precio base: $ ${'' + this.currencyFormatterGeneral(this.precio_base)}`);
                    }
                }

            }
        }
    }

    successGuardarProducto(data) {
        this.getField('nit_buscar').setValue(0);
        this.getField('btnAgregarDatosCliente').setDisabled(false);
        if (data.estado_p === 200) {
            this.getField('modalAgregarProducto').handleClose();
            this.totalCantidadProductos = 0;

            if(data.data.item_bodegas[0].mostrar_dialog === true){
                this.codigoConsecutivo = data.data.item_bodegas[0].numero_pedido;
                this.getField('confirmModalCustom').setTitleAndContent('Número Pedido', 'El número para este pedido es: ' + this.codigoConsecutivo);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }
            this.uploadTable();
        }
    }
    
    consultarExistenciasBodegas() {
        if (this.getField('codigo_bodega_producto').getValue() !== '' && this.getField('producto_inicial').getValue() !== '') {
            let datos = { datos:{
                    codigo: this.getField('codigo_bodega_producto').getValue(),
                    codigo_sucursal:this.codigo_sucursal
                }
            };

            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'codigoporbodega', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.mostrarProductosBodegas,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage:false
                }
            );
        } else {
            this.getField('tablaTodasBodegas').toggle(false);
        }
    }

    mostrarProductosBodegas(data) {
        if (data.estado_p === 200) {
            console.log(data)
            if (data.data.data){
                this.gridOptionsProductoBodegas['rowData'] = data.data.data;
            }else{
                let configCell = new Map();
                this.gridOptionsProductoBodegas['rowData'] = data.data;
                configCell.set(7, { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.precio)}, type: 'rightAligned' });
                configCell.set(8, { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.precio_mas_iva)}, type: 'rightAligned' });
                this.getField('tablaTodasBodegas').initData(this.gridOptionsProductoBodegas,configCell);
            }
        } else {
            this.getField('tablaTodasBodegas').toggle(false);
            this.alertGeneral.toggle(true, 'No se encontraron bodegas', "error");
        }
    }

    buscarProductosFiltrar(){
        if(this.getField("nombre_filtrar").valid()){
            this.getField('tablaProductos').toggle(false);
            this.getField("nombre_filtrar").setDisabled(true);
            this.getField("btnFiltrar").setDisabled(true);

            let datos = { datos: {
                    cliente_id: this.cliente_id,
                    codigo_sucursal:this.codigo_sucursal,
                    bodega: this.saldo_bodegas_productos,
                    numero_pedido: this.codigoConsecutivo,
                    estado:"P",
                    nombre:this.getField("nombre_filtrar").getValue()
                }
            }; // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporclientefiltrar', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successUploadTable,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    traerProductosCoincidenciaNombreCoincidenciaMarca(data) {
        if (data.data.length > 1) {
            this.getField('modal_productos').handleClickOpen();
            this.gridOptionsProductos['rowData'] = data.data;
            let configCell = new Map();
            this.getField('tb_productos').initData(this.gridOptionsProductos, configCell);

        } else if (data.data.length === 1) {
            this.getField('modal_productos').handleClose();
            this.getField('tb_productos').toggle(false);
            this.getField('producto').setValue(data.data[0].codigo);
            this.getField('nombre_marca').setValue('.');
            this.getField('marca').setValue(data.data[0].marca);
            this.getField('tarifa_iva').setValue(data.data[0].tarifa_iva);
            this.getField('precio').setValue(data.data[0].precio);
            this.getField('precio_mas_iva').setValue(data.data[0].precio_mas_iva);
            this.getField('referencia').setValue(data.data[0].referencia);
            this.getField('presenta').setValue(data.data[0].presenta);
            this.getField('existencia').setValue(data.data[0].existencia);
            this.getField('articulo_id').setValue(data.data[0].id);
            this.getField('nombre_producto').setValue(data.data[0].nombre);
            this.getField('precio_incluye_iva').setValue(data.data[0].precio_incluye_iva);//
        }
    }

    calcularDigitoNit() {
        if (this.getField('nit').valid()) {
            let digito = "".calcularDigitoVerificacion(this.getField('nit').getValue());
            this.getField('verifica').setValue(digito);
        }
    }

    traerPaises() {
        this.getField('paises_id').setDisabled(true);
        this.datosConsultar = { datos: {
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-paises', operacion: 'todos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successTraerPaises,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successTraerPaises(data) {
        this.getField('paises_id').setDisabled(false);
        if (data.estado_p === 200 && data.data.length >= 1) {
            let arrayFiltrar = [];
            let itemIncrement = 0;
            data.data.map((itemFiltrar) => {
                if (itemFiltrar.estadoactivo_id === 1) {
                    arrayFiltrar.push(data.data[itemIncrement]);
                }
                itemIncrement += 1;
                return null;
            });
            let opciones = [{ text: 'Ninguno', value: '', 'campos_cambian': {} }];
            arrayFiltrar.forEach(item => {
                let dataOp = {}
                dataOp['value'] = `${item.id}`;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {};
                opciones.push(dataOp);
            })
            this.getField('paises_id').setOptions(opciones);
        } else {
            this.getField('ciudad_id').setOptions(this.opciones);
            this.getField('departamento_geografico_id').setOptions(this.opciones);
            this.getField('paises_id').setOptions(this.opciones);
        }
    }

    tipoDocumento() {
        if (this.getField('nombre_tipo_doc').getValue() === 'NIT') {
            this.getField('nombre').setDisabled(false);
            this.getField('nom1').setDisabled(true);
            this.getField('nom1').setError(false, '');
            this.getField('nom2').setDisabled(true);
            this.getField('nom2').setError(false, '');
            this.getField('ape1').setDisabled(true);
            this.getField('ape1').setError(false, '');
            this.getField('ape2').setDisabled(true);
            this.getField('ape2').setError(false, '');
            this.getField('nombre').setValue('');
            this.getField('nombre').setError(false, '');
            this.getField('nom1').setValue('.');
            this.getField('nom2').setValue('.');
            this.getField('ape1').setValue('.');
            this.getField('ape2').setValue('.');
        } else {
            this.getField('nombre').setDisabled(true);
            this.getField('nom1').setDisabled(false);
            this.getField('nom1').setError(false, '');
            this.getField('nom2').setDisabled(false);
            this.getField('nom2').setError(false, '');
            this.getField('ape1').setDisabled(false);
            this.getField('ape1').setError(false, '');
            this.getField('ape2').setDisabled(false);
            this.getField('ape2').setError(false, '');
            this.getField('nombre').setValue('');
            this.getField('nombre').setError(false, '');
            this.getField('nom1').setValue('.');
            this.getField('nom2').setValue('.');
            this.getField('ape1').setValue('.');
            this.getField('ape2').setValue('.');
        }
    }

    concatenarNombre() {
        let nom1 = this.getField('nom1').getValue();
        let nom2 = this.getField('nom2').getValue();
        let ape1 = this.getField('ape1').getValue();
        let ape2 = this.getField('ape2').getValue();
        let unionCampos = '';
        let arrayNombreFinal = [];
        let arrayUnionCampos = `${nom1},${nom2},${ape1},${ape2}`.split(/\u002C/g);// Se crea un array  partir de las comas (,), la expresión regular que tiene el "split" es del carácter coma.
        arrayUnionCampos.forEach((item) => {// La idea con este forEach es crear un arreglo sólo con nombres y apellidos con un valor diferente a punto (.)
            if (item.replace(/\u0020/g, '') !== '' && item.replace(/\u0020/g, '') !== '.') {// El replace se hace para eliminar los espacios
                arrayNombreFinal.push(item);
            }
        });
        let concatenarNombre = '';
        arrayNombreFinal.forEach((item) => {// Lo que se hace con este forEach es crear una cadena con los nombres y apellidos que están en el arreglo "arrayNombreFinal"
            concatenarNombre += `${item} `;
        });
        unionCampos = concatenarNombre.substring(0, concatenarNombre.length - 1);// Se quita el último carácter de la cadena, que para este caso sería un espacio.

        let estado = true;
        if (this.getField('nom1').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('nom2').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('ape1').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('ape2').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (unionCampos.replace(/\u0020/g, '') !== '' && estado) {
            estado = true;
        }else{
            estado= false;
        }

        if (estado) {
            this.getField('nombre').setValue(unionCampos);
        } else if (this.getField('nombre').getValue() !== '') {
            this.getField('nombre').setValue('');
            this.getField('nombre').setError(false, '');
        }
    }

    validarCamposApellidos() {
        if ((this.getField('ape1').getValue() !== '' && this.getField('ape1').getValue() !== '.' && this.getField('ape1').valid() && this.getField('ape2').valid()) ||
            (this.getField('ape2').getValue() !== '' && this.getField('ape2').getValue() !== '.' && this.getField('ape2').valid() && this.getField('ape1').valid())) {
            this.getField('ape1').setError(false, '');
            this.getField('ape2').setError(false, '');
        } else if (((this.getField('ape1').getValue()).replace(/\u0020/g, '') === '' || this.getField('ape1').getValue() === '.') &&
            ((this.getField('ape2').getValue()).replace(/\u0020/g, '') === '' || this.getField('ape2').getValue() === '.')) {
            this.getField('ape1').valid();
            this.getField('ape2').valid();
        }
    }

    traerInformacionPedidos(){
        let datos = { datos: {
                numero_pedido: this.getField("numero").getValue()
            }
        }; // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
        this.generalFormatPmv = { tipo_servicio: 'most-pedidos', operacion: 'traerinformacionpedidos', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.traerInfoPedidos,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    traerInfoPedidos(data) {

        if (data.estado_p === 200 && data.data[0].tipo === 'COTIZACION') {
            let datosFicha = [];
            let data2 = {
                "n._cotizacion": data.data[0].numero,
                "nit": data.data[0].nit,
                "sede": data.data[0].sede,
                "nombre": data.data[0].nombre,
            };
            this.getField('lista_informacion_pedidos').setVisible(true);
            datosFicha.push(data2);
            this.getField('lista_informacion_pedidos').setItemsFichas(datosFicha);

            this.getField('campo_carga_pedidos_traertodopornumero').setValue(data.data[0].numero);
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Error Pedido', `Este pedido no es una cotización`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('numero').setValue(''); this.getField('numero').setError(false, ''); this.itemsPedido = []; this.arrayFinalIntegrarItemsCotizacion = []; this.preciosMostradorProductosCrear = ''; this.nitsxSedesIdMostradorProductosCrear = 0; this.contadorIntegrar = 0; this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    validarItemsCotizacionIntegrar() {

        if (this.itemsPedido.length >= 1 && this.arrayFinalIntegrarItemsCotizacion.length >= 1 &&
            this.arrayFinalIntegrarItemsCotizacion.length === this.itemsPedido.length) {
            let arrayTem = [];
            this.arrayFinalIntegrarItemsCotizacion.forEach((item) => {
                if (item.cantidad > item.saldo_bodega) {
                    arrayTem.push(item);
                }
            });

            if (arrayTem.length === 0) {
                this.codigoConsecutivo = 0;
                this.intgrarItemsCotizacion();
            } else if (arrayTem.length > 1 && this.arrayFinalIntegrarItemsCotizacion.length > 1) {
                this.getField('confirmModalCustom').setTitleAndContent('AVISO', `De los ${this.arrayFinalIntegrarItemsCotizacion.length} artículos a integrar, ${arrayTem.length} artículos no pueden ser integrados por no haber su cantidad total de existencia en saldo bodega.\n ¿Desea continuar con la operación?`);
                this.getField('confirmModalCustom').setClickDialog(() => { arrayTem = []; this.getField('confirmModalCustom').toggle(false); this.intgrarItemsCotizacion(); });
                this.getField("confirmModalCustom").setVisibleCancel(false); // false para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonCancel("Cancelar")
                this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            } else if (arrayTem.length === 1 && this.arrayFinalIntegrarItemsCotizacion.length === 1) {
                this.getField('confirmModalCustom').setTitleAndContent('AVISO', `El artículo no puede ser integrado por no haber su cantidad total de existencia en saldo bodega.`);
                this.getField('confirmModalCustom').setClickDialog(() => {
                    arrayTem = []; this.arrayMsjConfimDialog = [];
                    //this.pedidosPendientes(); 
                    this.arrayNuevo = [];
                    this.nuevoPedidosPendientes();
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            } else if (arrayTem.length === 1 && this.arrayFinalIntegrarItemsCotizacion.length > 1) {
                this.getField('confirmModalCustom').setTitleAndContent('AVISO', `De los ${this.arrayFinalIntegrarItemsCotizacion.length} artículos a integrar, ${arrayTem.length} artículo no puede ser integrado por no haber su cantidad total de existencia en saldo bodega.\n ¿Desea continuar con la operación?`);
                this.getField('confirmModalCustom').setClickDialog(() => { arrayTem = []; this.getField('confirmModalCustom').toggle(false); this.intgrarItemsCotizacion(); });
                this.getField("confirmModalCustom").setVisibleCancel(false); // false para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonCancel("Cancelar")
                this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }
        }
    }

    intgrarItemsCotizacion() {
        if (this.preciosMostradorProductosCrear !== '' && this.nitsxSedesIdMostradorProductosCrear !== 0 &&
            this.itemsPedido.length >= 1 && this.contadorIntegrar < this.itemsPedido.length && this.getField('sucursal_ingreso').getValue() !== '') {

            let datos = { datos:{
                sucursal_ingreso: this.getField('sucursal_ingreso').getValue(),
                nitsx_sedes_id: this.nitsxSedesIdMostradorProductosCrear,
                articulo_id: this.itemsPedido[this.contadorIntegrar].articulo_id,
                cantidad: this.itemsPedido[this.contadorIntegrar].cantidad,
                descuento: this.itemsPedido[this.contadorIntegrar].descuento,
                precio: this.itemsPedido[this.contadorIntegrar].precio_base,
                precios: this.preciosMostradorProductosCrear,
                numero_pedido: this.codigoConsecutivo,
                tipo_pedido: "Entrega Inmediata",
                tipo_despacho: "Envio Local",
                observacion: ".",
                remision: this.maneja_remision === true? this.getField("remision_producto").getValue():"N",
            }}
            this.getField('btn_integrar').setDisabled(true);
            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'crear', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'POST',
                body: datos,
                success: this.successMostradorProductosCrear,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successMostradorProductosCrear(data) {

        if (data.estado_p === 200) {

            this.codigoConsecutivo = data.data.item_bodegas[0].numero_pedido;
            this.alertGeneral.toggle(true, 'Acción Exitosa', 'success');
            this.contadorIntegrar += 1;
            this.validarServicioMostradorProdCrear();
        } else if (data.estado_p === 400) {

            this.estadoBodegasExistencias = true;

            let objectTemp = {
                "Código del Artículo": this.itemsPedido[this.contadorIntegrar].codigo,
                "Nombre del Artículo": this.itemsPedido[this.contadorIntegrar].nombre,
            }
            let stringifiedObj = `[${Object.entries(objectTemp).map(x => x.join(": ")).join(";\n")}]`;

            this.arrayMsjConfimDialog.push(stringifiedObj);

            this.contadorIntegrar += 1;
            this.validarServicioMostradorProdCrear();
        }
    }

    validarServicioMostradorProdCrear() {
        if (this.contadorIntegrar < this.itemsPedido.length) {
            this.intgrarItemsCotizacion();
        } else {
            if (this.estadoBodegasExistencias === true) {
                this.getField('confirmModalCustom').setTitleAndContent('Error existencias', `Los siguientes productos no contienen suficientes existencias en bodegas: ${this.arrayMsjConfimDialog.toString().replace(',', ' /\n')}`);
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.arrayMsjConfimDialog = [];
                    this.arrayNuevo = [];
                    this.nuevoPedidosPendientes();
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            } else if (this.estadoBodegasExistencias === false) {
                this.getField('btn_integrar').setDisabled(false);
                this.arrayNuevo = [];
                this.cambiarEstadoDescuento();
                this.nuevoPedidosPendientes();
            }
        }
    }

    cambiarEstadoDescuento(){
        let datos = { datos: {
            numero_pedido: this.getField("numero").getValue()
        }
        }; // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'cambioestadocotizacion', operacion_tipo: 'modificar' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.succesCambioEstado,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

    succesCambioEstado(data){
        if(data.estado_p === 200){
            this.itemsPedido = [];
            this.arrayFinalIntegrarItemsCotizacion = [];
            this.preciosMostradorProductosCrear = '';
            this.nitsxSedesIdMostradorProductosCrear = 0;
            this.contadorIntegrar = 0;
            this.getField('numero').setValue('');
            this.getField('numero').setError(false, '');
        }else { 
            this.alertGeneral.toggle(true,data.data.mensaje,"error");
        } 
    }

    traertodopornumero(){
        let datos = { datos: {
                numero_pedido: this.getField("campo_carga_pedidos_traertodopornumero").getValue()
            }
        }; // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
        this.generalFormatPmv = { tipo_servicio: 'most-pedidos', operacion: 'traertodopornumero', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.itemsPedidoPorNumero,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    itemsPedidoPorNumero(data) {

        this.itemsPedido = data.data[0].items;
        this.preciosMostradorProductosCrear = data.data[0].pedido.lista_precios;
        
        let datos = { datos:{
            nit: data.data[0].pedido.nit,
            sede: data.data[0].pedido.sede,
            estado: 'A',
        }};

        this.generalFormatPmv = { tipo_servicio: 'most-terceros', operacion: 'nitsede', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successTraerIdClientePorNitSede,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
        this.mostProdBodegaSegunSucursal();
    }

    successTraerIdClientePorNitSede(data) {

        if (data.estado_p === 200 && data.data) {

            let objectTemp = {
                "Nit Cliente": data.data[0].nit,
                "Sede": data.data[0].sede,
                "Nombre Cliente": data.data[0].nombre,
            }
            // Fuente de documentación: https://www.delftstack.com/es/howto/javascript/javascript-object-to-string/
            let stringifiedObj = `${Object.entries(objectTemp).map(x => x.join(": ")).join(";\n")}`;

            this.arrayMsjConfimDialog.push(stringifiedObj);

            this.nitsxSedesIdMostradorProductosCrear = data.data[0].id;

            this.getField('campo_carga_most_productosporcliente').setValue(data.data[0].id);
        }
    }

    mostProdBodegaSegunSucursal() {
        let datos = { datos:{
            codigo_sucursal: JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'saldobodegasproductos', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.mostProdSucursalBodegas,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

    mostProdSucursalBodegas(data) {

        if (data.estado_p === 200 && data.data.length >= 1) {
            let arrayCodProd = [];
            this.itemsPedido.forEach((item) => {
                arrayCodProd.push(item.codigo);
            });

            let datos = { datos:{
                productos: arrayCodProd.toString(),
                bodegas: data.data[0].saldo_bodegas_productos,
            }};
            this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'sucursalbodegas', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successMostProdSucursalBodegas,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                }
            );
        } else if (data.estado_p === 502) {
            this.getField('confirmModalCustom').setTitleAndContent('Error Bodegas', `${data.data.errores.value !== undefined && data.data.errores.value !== '' && data.data.errores.value !== null ? data.data.errores.value : data.data.errores.codigo_sucursal}`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('numero').setValue(''); this.getField('numero').setError(false, ''); this.itemsPedido = []; this.arrayFinalIntegrarItemsCotizacion = []; this.preciosMostradorProductosCrear = ''; this.nitsxSedesIdMostradorProductosCrear = 0; this.contadorIntegrar = 0; this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    successMostProdSucursalBodegas(data) {

        if (data.estado_p === 200 && data.data.length >= 1) {
            if (data.data.length === this.itemsPedido.length) {
                for (let i = 0; i < this.itemsPedido.length; i++) {
                    data.data.forEach((item) => {
                        if (item.codigo === this.itemsPedido[i].codigo) {
                            let existencias = {};
                            existencias['saldo_bodega'] = item.existencias;
                            let objetoFinal = Object.assign(this.itemsPedido[i], existencias);
                            this.arrayFinalIntegrarItemsCotizacion.push(objetoFinal);
                        }
                    });
                }
               
            }
        } else if (data.estado_p === 502) {
            this.getField('confirmModalCustom').setTitleAndContent('Error Saldo Bodegas', `${data.data.errores.mensaje}`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('numero').setValue(''); this.getField('numero').setError(false, ''); this.itemsPedido = []; this.arrayFinalIntegrarItemsCotizacion = []; this.preciosMostradorProductosCrear = ''; this.nitsxSedesIdMostradorProductosCrear = 0; this.contadorIntegrar = 0; this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        } else if (data.estado_p === 404) {
            this.getField('confirmModalCustom').setTitleAndContent('Error Saldo Bodegas', `${data.data.mensaje}`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('numero').setValue(''); this.getField('numero').setError(false, ''); this.itemsPedido = []; this.arrayFinalIntegrarItemsCotizacion = []; this.preciosMostradorProductosCrear = ''; this.nitsxSedesIdMostradorProductosCrear = 0; this.contadorIntegrar = 0; this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }

        
    }

    clientesPendientes() {

        let datos = { datos:{
            cliente_id: this.getField('campo_carga_most_productosporcliente').getValue(),   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            bodega:this.saldo_bodegas_productos,
            codigo_sucursal:this.codigo_sucursal,
            numero_pedido: this.codigoConsecutivo > 0 ? this.codigoConsecutivo:1,
            estado:"P"
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporcliente', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successClientesPendientes,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

    successClientesPendientes(data){

        let valorTotalItemsCotizacion = 0;
        this.itemsPedido.forEach((item) => {

            valorTotalItemsCotizacion += item.precio_total;
        });

        this.getField('valor_total_items_cotizacion').setValue(valorTotalItemsCotizacion.toFixed(2));
        this.getField('modal_items_cotizacion').handleClickOpen(); // Primero se abre la modal antes de dibujarse la tabla para que las columnas de la tabla no se dañen.
        this.arrayMsjConfimDialog = [];
        this.getField('tb_items_cotizacion').toggle(true);
        this.gridOptionsItemsCotizacion['rowData'] = this.arrayFinalIntegrarItemsCotizacion;

        let configCell = new Map();
        configCell.set('precio_base', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.precio_base) }, headerName: 'Precio', type: 'rightAligned' });
        configCell.set('precio_total', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.precio_total) }, headerName: 'Total', type: 'rightAligned' });
        this.getField('tb_items_cotizacion').initData(this.gridOptionsItemsCotizacion, configCell);
    }

    buscarMarca(){

        let datos2={ datos: {
            value: this.getField("producto").getValue() === ""? this.getField("producto_remision").getValue() : this.getField("producto").getValue(),
            nombre_marca:this.getField("nombre_marca").getValue(),
            codigo_sucursal:this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosmarca', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos2,
            success: this.traerProductosCoincidenciaNombreCoincidenciaMarca,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
        
    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);       
    }
    
    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > 2) {
                decimal = (Number(number).toFixed(2) + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


}
FormJaivana.addController("most-listaclientes", CustomListaClientes);
export default CustomListaClientes;